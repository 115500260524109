@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~ladda/dist/ladda.min.css';
@import "~ngx-lightbox/lightbox.css";
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #01579B;
  --indigo: #6610f2;
  --purple: #6A1B9A;
  --pink: #f63869;
  --red: #B71C1C;
  --orange: #BF360C;
  --yellow: #ffc107;
  --green: #1B5E20;
  --teal: #004D40;
  --cyan: #006064;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3f165a;
  --secondary: #333b44;
  --success: #60c31f;
  --info: #2678d2;
  --warning: #ff9d04;
  --danger: #db1c24;
  --light: #e9ecef;
  --dark: #0b2645;
  --white: #fff;
  --black: #000;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3f165a;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #14071d;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #f63869;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d9d9d9;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d9d9d9;
}
.table tbody + tbody {
  border-top: 2px solid #d9d9d9;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #d9d9d9;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #d9d9d9;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c9bed1;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9b86a9;
}

.table-hover .table-primary:hover {
  background-color: #bdafc6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bdafc6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6c8cb;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #95999e;
}

.table-hover .table-secondary:hover {
  background-color: #b9bbbf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b9bbbf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d2eec0;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #ace08b;
}

.table-hover .table-success:hover {
  background-color: #c4e9ac;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c4e9ac;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2d9f2;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8eb9e8;
}

.table-hover .table-info:hover {
  background-color: #adccee;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #adccee;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe4b9;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffcc7c;
}

.table-hover .table-warning:hover {
  background-color: #ffdaa0;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffdaa0;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5bfc2;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ec898d;
}

.table-hover .table-danger:hover {
  background-color: #f2a9ad;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f2a9ad;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafb;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f5f7;
}

.table-hover .table-light:hover {
  background-color: #eaedf1;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eaedf1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bbc2cb;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #808e9e;
}

.table-hover .table-dark:hover {
  background-color: #adb5c0;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #adb5c0;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}
.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #d9d9d9;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #2c2c2b;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #872fc0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #60c31f;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(96, 195, 31, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #60c31f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2360c31f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #60c31f;
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #60c31f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2360c31f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #60c31f;
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #60c31f;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #60c31f;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #60c31f;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #79df36;
  background-color: #79df36;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #60c31f;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #60c31f;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #60c31f;
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #db1c24;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(219, 28, 36, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #db1c24;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db1c24' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db1c24' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #db1c24;
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #db1c24;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db1c24' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db1c24' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #db1c24;
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #db1c24;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #db1c24;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #db1c24;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e7434a;
  background-color: #e7434a;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #db1c24;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #db1c24;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #db1c24;
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3f165a;
  border-color: #3f165a;
}
.btn-primary:hover {
  color: #fff;
  background-color: #290e3b;
  border-color: #220c31;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #290e3b;
  border-color: #220c31;
  box-shadow: 0 0 0 0.2rem rgba(92, 57, 115, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3f165a;
  border-color: #3f165a;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #220c31;
  border-color: #1b0927;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 57, 115, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #333b44;
  border-color: #333b44;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #23282e;
  border-color: #1d2227;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #23282e;
  border-color: #1d2227;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 96, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #333b44;
  border-color: #333b44;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1d2227;
  border-color: #181b20;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 96, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #60c31f;
  border-color: #60c31f;
}
.btn-success:hover {
  color: #fff;
  background-color: #50a21a;
  border-color: #4a9718;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #50a21a;
  border-color: #4a9718;
  box-shadow: 0 0 0 0.2rem rgba(120, 204, 65, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #60c31f;
  border-color: #60c31f;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4a9718;
  border-color: #458c16;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 204, 65, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #2678d2;
  border-color: #2678d2;
}
.btn-info:hover {
  color: #fff;
  background-color: #2065b2;
  border-color: #1e5fa7;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #2065b2;
  border-color: #1e5fa7;
  box-shadow: 0 0 0 0.2rem rgba(71, 140, 217, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2678d2;
  border-color: #2678d2;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1e5fa7;
  border-color: #1c599c;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 140, 217, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ff9d04;
  border-color: #ff9d04;
}
.btn-warning:hover {
  color: #fff;
  background-color: #dd8700;
  border-color: #d07f00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #dd8700;
  border-color: #d07f00;
  box-shadow: 0 0 0 0.2rem rgba(222, 139, 10, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ff9d04;
  border-color: #ff9d04;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d07f00;
  border-color: #c37700;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 139, 10, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #db1c24;
  border-color: #db1c24;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b9181e;
  border-color: #ae161d;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #b9181e;
  border-color: #ae161d;
  box-shadow: 0 0 0 0.2rem rgba(224, 62, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #db1c24;
  border-color: #db1c24;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ae161d;
  border-color: #a2151b;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 62, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-light:hover {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #cbd3da;
  border-color: #c4ccd4;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #0b2645;
  border-color: #0b2645;
}
.btn-dark:hover {
  color: #fff;
  background-color: #061424;
  border-color: #040e19;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #061424;
  border-color: #040e19;
  box-shadow: 0 0 0 0.2rem rgba(48, 71, 97, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #0b2645;
  border-color: #0b2645;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #040e19;
  border-color: #02080e;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 71, 97, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-outline-primary {
  color: #3f165a;
  border-color: #3f165a;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #3f165a;
  border-color: #3f165a;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3f165a;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3f165a;
  border-color: #3f165a;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.5);
}

.btn-outline-secondary {
  color: #333b44;
  border-color: #333b44;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #333b44;
  border-color: #333b44;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 59, 68, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #333b44;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #333b44;
  border-color: #333b44;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 59, 68, 0.5);
}

.btn-outline-success {
  color: #60c31f;
  border-color: #60c31f;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #60c31f;
  border-color: #60c31f;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #60c31f;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #60c31f;
  border-color: #60c31f;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.5);
}

.btn-outline-info {
  color: #2678d2;
  border-color: #2678d2;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #2678d2;
  border-color: #2678d2;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 120, 210, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #2678d2;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #2678d2;
  border-color: #2678d2;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 120, 210, 0.5);
}

.btn-outline-warning {
  color: #ff9d04;
  border-color: #ff9d04;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ff9d04;
  border-color: #ff9d04;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 4, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ff9d04;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ff9d04;
  border-color: #ff9d04;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 4, 0.5);
}

.btn-outline-danger {
  color: #db1c24;
  border-color: #db1c24;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #db1c24;
  border-color: #db1c24;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #db1c24;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #db1c24;
  border-color: #db1c24;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.5);
}

.btn-outline-light {
  color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #e9ecef;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-dark {
  color: #0b2645;
  border-color: #0b2645;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #0b2645;
  border-color: #0b2645;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 38, 69, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #0b2645;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #0b2645;
  border-color: #0b2645;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 38, 69, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3f165a;
  text-decoration: none;
}
.btn-link:hover {
  color: #14071d;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3f165a;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #2c2c2b;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3f165a;
  background-color: #3f165a;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #872fc0;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #9f4fd4;
  border-color: #9f4fd4;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3f165a;
  background-color: #3f165a;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 22, 90, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(63, 22, 90, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 22, 90, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 22, 90, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #2c2c2b;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #872fc0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #872fc0;
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #2c2c2b;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3f165a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #9f4fd4;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d9d9d9;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3f165a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #9f4fd4;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d9d9d9;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3f165a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #9f4fd4;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #d9d9d9;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d9d9d9;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #d9d9d9;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #d9d9d9;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #d9d9d9 #d9d9d9 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3f165a;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3f165a;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.page-link:hover {
  z-index: 2;
  color: #14071d;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #d9d9d9;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3f165a;
  border-color: #3f165a;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #d9d9d9;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3f165a;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #220c31;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 22, 90, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #333b44;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #1d2227;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 59, 68, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #60c31f;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #4a9718;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(96, 195, 31, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #2678d2;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #1e5fa7;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 120, 210, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ff9d04;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d07f00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 4, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #db1c24;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #ae161d;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(219, 28, 36, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #e9ecef;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #cbd3da;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #0b2645;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #040e19;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(11, 38, 69, 0.5);
}

.badge-white {
  color: #212529;
  background-color: #fff;
}
a.badge-white:hover, a.badge-white:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #000;
}
a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: black;
}
a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #210b2f;
  background-color: #d9d0de;
  border-color: #c9bed1;
}
.alert-primary hr {
  border-top-color: #bdafc6;
}
.alert-primary .alert-link {
  color: #040106;
}

.alert-secondary {
  color: #1b1f23;
  background-color: #d6d8da;
  border-color: #c6c8cb;
}
.alert-secondary hr {
  border-top-color: #b9bbbf;
}
.alert-secondary .alert-link {
  color: #050606;
}

.alert-success {
  color: #326510;
  background-color: #dff3d2;
  border-color: #d2eec0;
}
.alert-success hr {
  border-top-color: #c4e9ac;
}
.alert-success .alert-link {
  color: #1c3909;
}

.alert-info {
  color: #143e6d;
  background-color: #d4e4f6;
  border-color: #c2d9f2;
}
.alert-info hr {
  border-top-color: #adccee;
}
.alert-info .alert-link {
  color: #0c2542;
}

.alert-warning {
  color: #855202;
  background-color: blanchedalmond;
  border-color: #ffe4b9;
}
.alert-warning hr {
  border-top-color: #ffdaa0;
}
.alert-warning .alert-link {
  color: #533301;
}

.alert-danger {
  color: #720f13;
  background-color: #f8d2d3;
  border-color: #f5bfc2;
}
.alert-danger hr {
  border-top-color: #f2a9ad;
}
.alert-danger .alert-link {
  color: #45090b;
}

.alert-light {
  color: #797b7c;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}
.alert-light hr {
  border-top-color: #eaedf1;
}
.alert-light .alert-link {
  color: #606162;
}

.alert-dark {
  color: #061424;
  background-color: #ced4da;
  border-color: #bbc2cb;
}
.alert-dark hr {
  border-top-color: #adb5c0;
}
.alert-dark .alert-link {
  color: black;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-black hr {
  border-top-color: #ababab;
}
.alert-black .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3f165a;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3f165a;
  border-color: #3f165a;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #210b2f;
  background-color: #c9bed1;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #210b2f;
  background-color: #bdafc6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #210b2f;
  border-color: #210b2f;
}

.list-group-item-secondary {
  color: #1b1f23;
  background-color: #c6c8cb;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #1b1f23;
  background-color: #b9bbbf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1b1f23;
  border-color: #1b1f23;
}

.list-group-item-success {
  color: #326510;
  background-color: #d2eec0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #326510;
  background-color: #c4e9ac;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #326510;
  border-color: #326510;
}

.list-group-item-info {
  color: #143e6d;
  background-color: #c2d9f2;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #143e6d;
  background-color: #adccee;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #143e6d;
  border-color: #143e6d;
}

.list-group-item-warning {
  color: #855202;
  background-color: #ffe4b9;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #855202;
  background-color: #ffdaa0;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #855202;
  border-color: #855202;
}

.list-group-item-danger {
  color: #720f13;
  background-color: #f5bfc2;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #720f13;
  background-color: #f2a9ad;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #720f13;
  border-color: #720f13;
}

.list-group-item-light {
  color: #797b7c;
  background-color: #f9fafb;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #797b7c;
  background-color: #eaedf1;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #797b7c;
  border-color: #797b7c;
}

.list-group-item-dark {
  color: #061424;
  background-color: #bbc2cb;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #061424;
  background-color: #adb5c0;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #061424;
  border-color: #061424;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #d9d9d9;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3f165a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #220c31 !important;
}

.bg-secondary {
  background-color: #333b44 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1d2227 !important;
}

.bg-success {
  background-color: #60c31f !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4a9718 !important;
}

.bg-info {
  background-color: #2678d2 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1e5fa7 !important;
}

.bg-warning {
  background-color: #ff9d04 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d07f00 !important;
}

.bg-danger {
  background-color: #db1c24 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ae161d !important;
}

.bg-light {
  background-color: #e9ecef !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbd3da !important;
}

.bg-dark {
  background-color: #0b2645 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #040e19 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #d9d9d9 !important;
}

.border-top {
  border-top: 1px solid #d9d9d9 !important;
}

.border-right {
  border-right: 1px solid #d9d9d9 !important;
}

.border-bottom {
  border-bottom: 1px solid #d9d9d9 !important;
}

.border-left {
  border-left: 1px solid #d9d9d9 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3f165a !important;
}

.border-secondary {
  border-color: #333b44 !important;
}

.border-success {
  border-color: #60c31f !important;
}

.border-info {
  border-color: #2678d2 !important;
}

.border-warning {
  border-color: #ff9d04 !important;
}

.border-danger {
  border-color: #db1c24 !important;
}

.border-light {
  border-color: #e9ecef !important;
}

.border-dark {
  border-color: #0b2645 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-black {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3f165a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #14071d !important;
}

.text-secondary {
  color: #333b44 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #121518 !important;
}

.text-success {
  color: #60c31f !important;
}

a.text-success:hover, a.text-success:focus {
  color: #408115 !important;
}

.text-info {
  color: #2678d2 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #1a5391 !important;
}

.text-warning {
  color: #ff9d04 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b76f00 !important;
}

.text-danger {
  color: #db1c24 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #971319 !important;
}

.text-light {
  color: #e9ecef !important;
}

a.text-light:hover, a.text-light:focus {
  color: #bdc6cf !important;
}

.text-dark {
  color: #0b2645 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #000203 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #d9d9d9 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #d9d9d9;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #d9d9d9;
  }
}
a:hover, a:focus {
  text-decoration: none;
}

.pull-right {
  float: right !important;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0px;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f165a;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f165a;
}

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
  background-color: #3f165a;
}

.mat-fab.mat-success, .mat-flat-success.mat-success, .mat-mini-fab.mat-success, .mat-raised-button.mat-success {
  background-color: #60c31f;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f165a;
}

textarea.mat-input-element {
  min-height: 70px;
}

.tootip-class {
  background-color: #000;
  color: #fff;
  line-height: 17px;
}

.header-dropdown .mat-menu-item {
  line-height: 34px;
  height: 34px;
}
.header-dropdown .mat-menu-item:focus {
  outline: none;
}
@media (max-width: 767px) {
  .header-dropdown {
    position: absolute;
    right: 20px;
  }
}

.loader {
  position: absolute;
  left: 0px;
  border-radius: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.loader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #87d37c;
  -webkit-animation: loader3 1.5s linear infinite;
  animation: loader3 1.5s linear infinite;
}
.loader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
.custom-table td, .custom-table th {
  border-color: #d9d9d9;
  color: #6c757d;
  vertical-align: middle;
  padding: 0.85rem 0.65rem;
}
.custom-table thead th {
  color: #6c757d;
  background-color: #f0eeef;
  border-bottom: none;
  font-weight: 600;
}
.custom-table thead a {
  color: #6c757d;
}
.custom-table thead a:hover {
  color: #5d646b;
}
.custom-table thead tr td:last-child, .custom-table tbody tr td:last-child {
  text-align: center;
}

/*.dropdown-menu {opacity: 0; border: 0; transform: scale(0) !important; transform-origin: 0 0; will-change: transform, opacity; transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); box-shadow: 0 2px 2px 0 rgba($black, 0.14), 0 3px 1px -2px rgba($black, 0.2), 0 1px 5px 0 rgba($black, 0.12); top: 100% !important; left: 0px !important;
	.dropdown-item {position: relative; display: flex; flex-flow: row wrap; align-items: center; min-width: 7rem; max-width: 17.5rem; min-height: 2.2rem; padding: 0.4rem 1rem; overflow: hidden; line-height: 1; text-overflow: ellipsis; word-wrap: break-word; color: inherit;
	    &:hover {text-decoration: none; color: $primary;}
        &.active {color: $primary;}
    }
    &.show {opacity: 1; transform: scale(1) !important;}
} */
.card {
  border-radius: 0px;
  border-color: #cccccc;
}

.card-body {
  padding: 14px;
}

.card-header {
  padding: 0.65rem 14px;
}

.custom-dialog button:focus {
  outline: none;
}
.custom-dialog .mat-form-field {
  font-weight: 500;
}
.custom-dialog .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
}
.custom-dialog .header {
  padding: 20px;
}
.custom-dialog .header h3 {
  margin-bottom: 0px;
}
.custom-dialog .header .mat-button {
  padding: 0px 5px;
  min-width: 10px;
  font-size: 18px;
}
.custom-dialog .mat-dialog-content {
  margin: 0px;
  padding-top: 30px;
  max-height: 85vh;
}
.custom-dialog .mat-dialog-actions {
  margin-bottom: 6px;
  padding: 8px 25px;
}

.announce-dialog {
  position: absolute !important;
  right: 0px;
  top: 77px;
}
.announce-dialog button:focus {
  outline: none;
}
.announce-dialog .mat-form-field {
  font-weight: 500;
}
.announce-dialog .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
}
.announce-dialog .header {
  padding: 20px;
}
.announce-dialog .header h3 {
  margin-bottom: 0px;
}
.announce-dialog .header .mat-button {
  padding: 0px 5px;
  min-width: 10px;
  font-size: 18px;
}
.announce-dialog .mat-dialog-content {
  margin: 0px;
  padding-top: 30px;
  max-height: 85vh;
}
.announce-dialog .mat-dialog-actions {
  margin-bottom: 6px;
  padding: 8px 25px;
}

.privacy-dialog button:focus {
  outline: none;
}
.privacy-dialog .mat-form-field {
  font-weight: 500;
}
.privacy-dialog .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
}
.privacy-dialog .header {
  padding: 20px;
}
.privacy-dialog .header h3 {
  margin-bottom: 0px;
}
.privacy-dialog .header .mat-button {
  padding: 0px 5px;
  min-width: 10px;
  font-size: 18px;
}
.privacy-dialog .mat-dialog-content {
  margin: 0px;
  padding-top: 30px;
  max-height: 455px;
  min-height: 455px;
}
.privacy-dialog .mat-dialog-actions {
  margin-bottom: 6px;
  padding: 8px 25px;
}
@media (max-width: 767px) {
  .privacy-dialog .mat-dialog-actions button {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .privacy-dialog .privacy-scroll {
    min-height: calc(100vh - 200px) !important;
  }
  .privacy-dialog .mat-dialog-content {
    max-height: calc(100vh - 120px) !important;
    min-height: calc(100vh - 110px) !important;
    padding: 0px !important;
  }
  .privacy-dialog .friends-privacy {
    padding: 10px 10px 0px 10px;
  }
  .privacy-dialog .friends-privacy .mat-checkbox {
    padding-left: 8px;
  }
}

.embed-dialog button:focus {
  outline: none;
}
.embed-dialog .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
}
.embed-dialog .mat-dialog-content {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}
.embed-dialog .priview .view {
  width: calc(100% - 285px);
  float: left;
}
.embed-dialog .priview .view iframe {
  width: 100%;
  margin-bottom: -7px;
}
.embed-dialog .priview .caption {
  width: 285px;
}
.embed-dialog .priview .caption .header {
  padding: 10px;
}
.embed-dialog .priview .caption .links {
  width: 100%;
  float: left;
  padding: 15px;
}
.embed-dialog .priview .caption .links pre {
  width: 100%;
  float: left;
  overflow: hidden;
  white-space: inherit;
}

.custom-card {
  margin-bottom: 30px;
}
.custom-card .card-title {
  text-align: center;
  border-bottom: 1px solid #cccccc;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 12px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.card.invites h4 {
  font-size: 16px;
  font-weight: bold;
}
.card.invites p {
  font-size: 14px;
}
.card.invites label {
  font-size: 16px;
  font-weight: bold;
}
.card.invites .mat-flat-button {
  padding: 6px 8px;
}

.ad-box {
  margin-bottom: 30px;
}
.ad-box video {
  width: 100%;
}
.ad-box iframe {
  width: 100%;
}
.ad-box img {
  width: 100%;
}

ul.explore-menu {
  list-style: none;
  line-height: 36px;
}
ul.explore-menu > li > a {
  color: #2c2c2b;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 600;
}
ul.explore-menu > li > a .fa {
  width: 24px;
  color: #3f165a;
  font-size: 16px;
}
ul.explore-menu > li > a:hover, ul.explore-menu > li > a.active {
  text-decoration: none;
  color: #3f165a;
}

ul.trends-list {
  list-style: none;
}
ul.trends-list li h6 {
  font-size: 15px;
  font-weight: 800;
  color: #2c2c2b;
  margin-bottom: 2px;
}
ul.trends-list li h6 a {
  color: inherit;
}
ul.trends-list li h6 a:hover {
  text-decoration: none;
  color: #3f165a;
}
ul.trends-list li p {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  color: #2c2c2b;
}

/*ul.suggestion-list {
    li {padding: 0.75rem;
        &:first-child {padding-top: 0px; border-top: none;}
        .image {width: 26px; margin-right: 5px; float: left;}
        .caption {width: calc(100% - 36px); float: left;
            h6 {margin-bottom: 0px; font-size: 14px; font-weight: 600; color: $gray-400; margin-bottom: 0px; flex-basis: 0; flex-grow: 1; max-width: 100%; padding-right: 5px;
                a {color: inherit; }
                a:hover {text-decoration: none; color: $primary;}
            }
            .button .btn {font-size: 13px; font-weight: 600;}
        }
    }
}*/
ul.suggestion-list li {
  padding: 5px;
}
ul.suggestion-list li:first-child {
  padding-top: 0px;
  border-top: none;
}
ul.suggestion-list li .image {
  width: 31px;
  margin-right: 5px;
  float: left;
}
ul.suggestion-list li .caption {
  width: calc(100% - 36px);
  float: left;
}
ul.suggestion-list li .caption h6 {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  color: #434342;
  margin-bottom: 0px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
ul.suggestion-list li .caption h6 a {
  color: inherit;
}
ul.suggestion-list li .caption h6 a:hover {
  text-decoration: none;
  color: #3f165a;
}
ul.suggestion-list li .caption .button {
  width: 85px;
  text-align: right;
}
ul.suggestion-list li .caption .button .btn {
  font-size: 11px;
  font-weight: 700;
  padding: 0.25rem 0.2rem;
}
ul.suggestion-list li .caption .button .btn + .btn {
  margin-top: 0.2rem;
}
ul.suggestion-list li .caption .button .btn .fa {
  color: #3f165a;
  margin-right: 4px;
}
ul.suggestion-list li .caption .button .btn:hover .fa {
  color: #fff;
}

.profile-card {
  margin-bottom: 30px;
}
.profile-card .card-header {
  height: 60px;
  background-color: #3f165a;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  border-radius: 0px;
}
.profile-card .profile {
  position: relative;
}
.profile-card .profile .image {
  margin-top: -50px;
  width: 75px;
  margin-right: 10px;
}
.profile-card .profile .heading {
  width: calc(100% - 85px);
  margin-top: -5px;
}
.profile-card .profile .heading h6 {
  font-size: 15px;
  font-weight: 800;
  color: #2c2c2b;
  margin-bottom: 2px;
}
.profile-card .profile .heading h6 a {
  color: inherit;
}
.profile-card .profile .heading h6 a:hover {
  text-decoration: none;
  color: #3f165a;
}
.profile-card .profile .heading p {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  color: #2c2c2b;
}
.profile-card ul {
  list-style: none;
  padding: 0px;
  text-align: center;
  margin-bottom: 0px;
  margin-right: -4px;
  margin-left: -4px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
}
.profile-card ul li {
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
}
.profile-card ul li a {
  width: 100%;
  text-align: center;
  color: #2c2c2b;
}
.profile-card ul li a:hover {
  text-decoration: none;
  color: #3f165a;
}
.profile-card.absolute-pic .card-header {
  height: 50px;
}
.profile-card.absolute-pic .upload {
  position: absolute;
  right: 20px;
  bottom: 10px;
  z-index: 9;
  overflow: hidden;
}
.profile-card.absolute-pic .upload .btn {
  position: relative;
  padding: 2px 7px;
  font-size: 14px;
  margin: 0px;
}
.profile-card.absolute-pic .upload input[type=file], .profile-card.absolute-pic .upload input[type=button] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  opacity: 0;
  cursor: pointer;
}
.profile-card.absolute-pic .profile .image {
  position: absolute;
  top: -70px;
  left: 50%;
  margin-left: -50px;
  width: 100px;
}
.profile-card.absolute-pic .profile .image img {
  border: 4px solid #fff;
  width: 100%;
}
.profile-card.absolute-pic .profile .image h6 {
  position: absolute;
  top: 25px;
  left: 100%;
  white-space: nowrap;
  color: #fff;
  font-weight: 800;
  padding-left: 15px;
}
.profile-card.absolute-pic .profile .heading {
  width: 100%;
}
.profile-card.absolute-pic .profile .heading h6 {
  font-size: 18px;
  margin-bottom: 8px;
}
@media (max-width: 991px) {
  .profile-card {
    margin-bottom: 15px;
  }
}
@media (max-width: 574px) {
  .profile-card {
    margin-bottom: 15px;
  }
  .profile-card.absolute-pic {
    margin-bottom: 0px;
  }
  .profile-card.absolute-pic .profile .image {
    top: -30px;
  }
  .profile-card.absolute-pic .profile .image h6 {
    display: none;
  }
  .profile-card.absolute-pic .profile .heading {
    margin-top: 15px;
  }
}

.page-card .mat-form-field-appearance-outline .mat-form-field-infix {
  font-weight: 500;
}
.page-card .card-header {
  background-color: transparent;
  padding: 0.65rem 25px;
}
.page-card .card-header .card-title {
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
}
.page-card .card-header a:hover, .page-card .card-header a:focus {
  text-decoration: none;
}
.page-card .card-header .btn + .btn {
  margin-left: 10px;
}
.page-card .card-header .btn:focus {
  text-decoration: none;
  outline: none;
}
.page-card .card-header .btn .fa-plus-circle {
  margin-left: 4px;
}
.page-card .card-header .mat-stroked-button + .mat-stroked-button {
  margin-left: 10px;
}
.page-card .card-header .input-group {
  max-width: 220px;
}
.page-card .card-header .input-group .form-control {
  padding-right: 35px;
  border-color: #d9d9d9;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-card .card-header .input-group .mat-stroked-button {
  min-width: 4px;
  position: absolute;
  top: 2px;
  right: 0px;
  box-shadow: none;
  border: none;
  z-index: 9;
  padding: 0px 10px;
}
.page-card .card-body {
  padding: 1rem 25px;
}
.page-card .btn-lg, .page-card .btn-group-lg > .btn {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.9rem 1rem;
}
.page-card .btn-link {
  font-weight: 700;
  font-size: 14px;
}
.page-card .btn-link:hover {
  text-decoration: none;
}

.timeline-menu {
  background-color: #fff;
  margin-left: -30px;
}
.timeline-menu .nav-link {
  border-left: 1px solid #cccccc;
  font-weight: 700;
  color: #666666;
  padding: 12px 6px;
}
.timeline-menu .nav-link:hover {
  color: #3f165a;
}
.timeline-menu .nav-link.active {
  background-color: #f3e6fc;
  color: #3f165a;
}
.timeline-menu .nav {
  border-bottom: 1px solid #cccccc;
}
.timeline-menu .mat-tab-label {
  min-width: 106px;
  opacity: 0.9;
  border-right: 1px solid #c5c5c5;
}
.timeline-menu .mat-tab-label a {
  padding: 0 10px;
  position: relative;
  color: #2c2c2b;
  font-size: 15px;
}
.timeline-menu .mat-tab-label a.active {
  background-color: rgba(197, 202, 233, 0.3);
}
.timeline-menu .mat-tab-label a.active:before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #3f165a;
  left: 0px;
  bottom: 0px;
  position: absolute;
}
.timeline-menu .mat-tab-labels .mat-tab-label:last-child {
  border-right: none;
}
.timeline-menu .mat-tab-group.mat-primary .mat-ink-bar, .timeline-menu .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}
@media (max-width: 991px) {
  .timeline-menu {
    margin-left: 0px;
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    margin-bottom: 15px;
    float: left;
    width: 100%;
  }
  .timeline-menu .mat-tab-label {
    min-width: 80px;
  }
  .timeline-menu .mat-tab-group {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 767px) {
  .timeline-menu .nav-link {
    padding: 6px 4px;
  }
  .timeline-menu .mat-tab-label {
    min-width: 40px;
  }
}

.new-post {
  margin-bottom: 30px;
}
.new-post .comment.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.3em 0 0.3em 0;
}
.new-post .card-title {
  font-size: 16px;
  font-weight: bold;
  color: #2c2c2b;
}
.new-post textarea.mat-input-element {
  height: 70px;
}
.new-post .select-file .custom-file {
  height: calc(1.5em + 0.75rem + 14px);
}
.new-post .select-file .custom-file-label {
  border-color: rgba(0, 0, 0, 0.12);
  height: calc(1.9em + 0.75rem + 9px);
  padding: 0.775rem 0.75rem;
}
.new-post .select-file .custom-file-label:after {
  height: calc(2.3em + 0.75rem);
  padding: 0.775rem 0.75rem;
}
.new-post .button ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.new-post .button ul li {
  float: left;
  position: relative;
}
.new-post .button ul li + li::before {
  content: "|";
  padding: 0px 4px;
  position: absolute;
  top: -2px;
  left: -5px;
  color: #3f165a;
}
.new-post .button ul li button, .new-post .button ul li label {
  display: flex;
  font-size: 12px;
  font-weight: 700;
  color: #3f165a;
  border: none;
  align-items: center;
  background-color: transparent;
  padding: 0px 4px;
  cursor: pointer;
}
.new-post .button ul li button .fa, .new-post .button ul li label .fa {
  margin-right: 5px;
  color: #b3b3b3;
  font-size: 15px;
}
.new-post .button ul li button .custom-file-input, .new-post .button ul li label .custom-file-input {
  position: absolute;
  pointer-events: none;
}
.new-post .button ul li button:focus, .new-post .button ul li label:focus {
  outline: none;
}
.new-post .button .mat-form-field {
  width: 90px;
  margin: 0px 3px;
}
.new-post .button .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 5px 0px 6px 0px;
  margin-top: -5px;
}
.new-post .button .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%);
}
.new-post .button .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px;
  padding: 0px;
}
.new-post .button .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 0.45em 0 0.45em;
}
.new-post .button .mat-checkbox-layout {
  margin: 0px;
}
.new-post .button .btn-post {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 15px;
}
@media (max-width: 767px) {
  .new-post .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}
@media (max-width: 499px) {
  .new-post .emoji-mart {
    right: auto !important;
    left: -16px;
    width: 305px !important;
  }
}

.user-banner {
  position: relative;
}
.user-banner .upload {
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 9;
}
.user-banner .upload .btn {
  position: relative;
  padding: 0.235rem 0.55rem;
}
.user-banner .upload input[type=file], .user-banner .upload input[type=button] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  opacity: 0;
  cursor: pointer;
}
.user-banner .info {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.user-banner .image {
  width: 100%;
  float: left;
  height: 100%;
}
.user-banner .button {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.user-banner .button a {
  background-color: #fff;
}
.user-banner .button a.mat-primary {
  background-color: #3f165a;
  color: #fff;
}
@media (max-width: 574px) {
  .user-banner .info {
    right: 4px;
    bottom: 4px;
  }
}

.timeline-post {
  margin-bottom: 30px;
}
.timeline-post a:hover, .timeline-post a:focus {
  outline: none;
}
.timeline-post .dropdown-menu {
  z-index: 10;
}
.timeline-post .card-header {
  background-color: transparent;
  padding: 8px;
}
.timeline-post .card-header .dropdown-menu {
  left: auto !important;
  right: 0px;
  border-radius: 0px;
}
.timeline-post .card-header .dropdown-menu .dropdown-item {
  border-radius: 0px;
  text-align: left;
  line-height: 18px;
  padding: 4px 12px;
  min-width: 34px;
  color: #3f165a;
}
.timeline-post .card-header .dropdown-menu .dropdown-item:focus {
  color: #fff;
}
.timeline-post .card-header .profile {
  width: 40px;
  float: left;
  margin-right: 10px;
}
.timeline-post .card-header .caption {
  width: calc(100% - 50px);
  float: left;
}
.timeline-post .card-header .handing h5 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  white-space: inherit;
}
.timeline-post .card-header .handing h5 a:hover {
  text-decoration: none;
}
.timeline-post .card-header .handing h5 span {
  color: #2c2c2b;
  font-size: 13px;
}
.timeline-post .card-header .handing .pin-post {
  transform: rotate(45deg);
  transform-origin: 20% 40%;
  margin-left: 1rem;
}
.timeline-post .card-header .time time {
  font-size: 13px;
  color: #a6a6a6;
}
.timeline-post .card-header .time .btn-link {
  padding: 0px 8px;
  font-size: 16px;
  line-height: 28px;
}
.timeline-post .card-header .time .btn-link:focus {
  text-decoration: none;
  outline: none;
}
.timeline-post .card-header .button .btn-link {
  padding-right: 0px;
  padding-top: 0px;
  font-size: 16px;
}
.timeline-post .card-header .button .btn-link:after {
  content: none;
}
.timeline-post .card-header .button .btn-link .fa {
  transform: scale(1.2);
}
.timeline-post .card-header .button .btn-link:focus {
  text-decoration: none;
  outline: none;
}
.timeline-post .group-image {
  width: 100%;
}
.timeline-post .group-image video {
  width: 100%;
  height: 100%;
  max-height: 350px;
}
.timeline-post .group-image .videos {
  position: relative;
}
.timeline-post .group-image .videos:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  /*background: rgba($black, 0.4);*/
}
.timeline-post .group-image .videos .fas {
  position: absolute;
  color: #fff;
  top: 15px;
  right: 15px;
}
.timeline-post .group-image .open-image {
  cursor: pointer;
}
.timeline-post .group-image .w-50 {
  position: relative;
}
.timeline-post .group-image .w-50 img-lazy {
  height: 222px;
}
.timeline-post .group-image .w-50 figure {
  height: 222px;
}
.timeline-post .group-image .w-50 figure img {
  height: 100%;
}
.timeline-post .group-image .img-count-1 .w-50 {
  width: 100% !important;
}
.timeline-post .group-image .img-count-3 .w-50:first-child {
  width: 100% !important;
}
.timeline-post .group-image .hide {
  display: none;
}
.timeline-post .group-image .more-images {
  position: absolute;
  width: 100%;
  height: calc(100% - 7px);
  top: 0px;
  left: 0px;
  color: #fff;
  font-size: 40px;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}
.timeline-post .card-text {
  font-size: 14px;
  color: #121212;
  line-height: 28px;
  font-weight: 600;
}
.timeline-post .like-comment-count {
  font-weight: 600;
  font-size: 13px;
  color: #999999;
}
.timeline-post .like-comment-count .fa {
  margin-right: 6px;
  transform: scale(1.3);
  color: #3f165a;
}
.timeline-post .reaction {
  padding: 5px;
  display: block;
  border-radius: 10px;
  max-width: 500px;
  display: flex;
  bottom: 25px;
  top: auto;
  left: -10px;
  z-index: 9;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
.timeline-post .reaction li {
  padding: 3px;
  float: left;
}
.timeline-post .reaction li a {
  cursor: pointer;
}
.timeline-post .reaction li img {
  width: 26px;
}
.timeline-post .comment-share .count {
  position: relative;
}
.timeline-post .comment-share .count:hover .reaction {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}
.timeline-post .comment-share .btn {
  padding: 0px 5px;
  font-size: 13px;
  font-weight: 600;
  color: #999999;
}
.timeline-post .comment-share .btn .fa {
  margin-right: 6px;
  transform: scale(1.3);
  color: #3f165a;
}
.timeline-post .comment-share .btn:hover {
  text-decoration: none;
  color: #3f165a;
}
.timeline-post .comment-share .btn:focus {
  text-decoration: none;
  outline: none;
}
.timeline-post .comments textarea.mat-input-element {
  height: 70px;
}
.timeline-post .comments .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.3em 0 0.3em 0;
}
.timeline-post .comments .mat-form-field-wrapper {
  padding: 0px;
  margin: 0px;
}
.timeline-post .comments .profile {
  width: 40px;
  float: left;
}
.timeline-post .comments .caption {
  width: calc(100% - 40px);
  float: left;
}
.timeline-post .comment-list .group {
  margin-bottom: 12px;
}
.timeline-post .comment-list .group .profile {
  width: 40px;
  float: left;
}
.timeline-post .comment-list .group .caption {
  width: calc(100% - 40px);
  float: left;
}
.timeline-post .comment-list .group .caption h6 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
  padding-right: 15px;
}
.timeline-post .comment-list .group .caption h6 a:hover {
  text-decoration: none;
}
.timeline-post .comment-list .group .caption h6 .action {
  position: absolute;
  right: 1px;
  top: 0px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.timeline-post .comment-list .group .caption h6 .action .dropdown-menu {
  padding: 0px;
  margin: 0px;
  min-width: 6rem;
  border-radius: 0px;
  right: 0px;
  transform: none !important;
  left: auto !important;
  top: 100% !important;
}
.timeline-post .comment-list .group .caption h6 .action .dropdown-menu .dropdown-item {
  border-radius: 0px;
  text-align: left;
  line-height: 30px;
  padding: 0 12px;
  min-width: 34px;
  color: #3f165a;
}
.timeline-post .comment-list .group .caption h6 .action .dropdown-menu .dropdown-item:focus {
  color: #fff;
}
.timeline-post .comment-list .group .caption h6 .dropdown-toggle {
  font-size: 12px;
  cursor: pointer;
}
.timeline-post .comment-list .group .caption h6 .dropdown-toggle:after {
  content: none;
}
.timeline-post .comment-list .group .caption .text {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: #535351;
}
.timeline-post .comment-list ul.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.timeline-post .comment-list ul.list .bg-list {
  background-color: rgba(63, 22, 90, 0.08);
  margin: 0px 0px 2px;
  padding: 7px 5px 5px;
}
.timeline-post .comment-list ul.list .bg-list:hover .action {
  opacity: 1;
}
.timeline-post .comment-list ul.list > div > li:last-child hr {
  display: none;
}
.timeline-post .comment-list ul.list ul.list {
  padding-left: 5px;
}
.timeline-post .comment-list .likes {
  margin-bottom: 14px;
  position: relative;
}
.timeline-post .comment-list .likes .btn {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
}
.timeline-post .comment-list .likes .btn b {
  color: #3f165a;
  font-weight: 700;
}
.timeline-post .comment-list .likes .btn .fa {
  margin-right: 6px;
  transform: scale(1.2);
  color: #3f165a;
}
.timeline-post .comment-list .likes .btn:hover {
  text-decoration: none;
  color: #3f165a;
}
.timeline-post .comment-list .likes .btn:focus {
  text-decoration: none;
  outline: none;
}
.timeline-post .comment-list .likes time {
  padding-left: 20px;
  font-size: 13px;
  color: #535351;
}
.timeline-post .comment-list .comments {
  margin-bottom: 14px;
}
.timeline-post .comment-list .comments textarea.mat-input-element {
  height: 50px;
}
.timeline-post .comment-list .reply-comments {
  padding: 0px 0px 0px 25px;
}
.timeline-post .comment-list .reply-comments ul.list .bg-list {
  padding: 6px 5px 1px;
  margin-bottom: 3px;
}
.timeline-post .comment-list .reply-comments ul.list .group {
  margin-bottom: 8px;
}
.timeline-post .comment-list .reply-comments ul.list .group .profile {
  width: 30px;
}
.timeline-post .comment-list .reply-comments ul.list .group .caption h6 {
  font-size: 13px;
  margin-bottom: 1px;
}
.timeline-post .comment-list .reply-comments ul.list .group .caption .text {
  font-size: 12px;
}
.timeline-post .comment-list .reply-comments ul.list .group .caption .text .emoji {
  width: 18px;
  height: 18px;
}
.timeline-post .comment-list .reply-comments ul.list .likes {
  margin-bottom: 6px;
}
.timeline-post .view-more .btn-link {
  padding: 0px;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  color: #2c2c2b;
  cursor: pointer;
}
.timeline-post .view-more .btn-link:after {
  content: none;
}
.timeline-post .view-more .btn-link .fa {
  transform: scale(1.2);
}
.timeline-post .view-more .btn-link:focus {
  text-decoration: none;
  outline: none;
}
.timeline-post .post-video-priview {
  border: 1px solid #ddd;
  float: left;
  height: 250px;
  position: relative;
  margin: 5px -15px -15px -15px;
  width: calc(100% + 30px);
}
.timeline-post .post-video-priview iframe {
  left: 0px;
  top: 0px;
  height: 100%;
}
@media (max-width: 767px) {
  .timeline-post hr {
    margin: 10px 0px;
  }
  .timeline-post .comments .caption {
    width: calc(100% - 70px);
  }
  .timeline-post .profile {
    width: 30px;
  }
  .timeline-post .like-comment-count {
    padding: 5px 10px;
  }
  .timeline-post .comment-list .comments textarea.mat-input-element {
    height: 35px;
    min-height: 35px;
  }
  .timeline-post .comment-list .likes {
    margin-bottom: 5px;
  }
  .timeline-post .comment-list .likes .btn {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
  }
  .timeline-post .comment-list .likes time {
    padding-left: 2px;
    font-size: 10px;
  }
}

.about-list + .about-list {
  margin-top: 15px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}
.about-list h4 {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 2px;
}
.about-list .mat-button {
  padding: 0px 8px;
  min-width: 10px;
  font-size: 18px;
  color: #3f165a;
}
.about-list .text h6 {
  font-size: 16px;
  font-weight: bold;
}

.friend-box {
  margin-bottom: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.friend-box .image img {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.friend-box .caption {
  padding: 8px;
}
.friend-box .caption h4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  line-height: 20px;
  word-break: break-word;
}
.friend-box .caption h4 a {
  color: inherit;
}
.friend-box .caption h4 a:hover, .friend-box .caption h4 a:focus {
  text-decoration: none;
  outline: none;
}
.friend-box .caption .button a {
  padding: 0 9px;
  min-width: 47px;
}
.friend-box .caption .button a + a {
  margin-left: 6px;
}
.friend-box .caption .button a:hover, .friend-box .caption .button a:focus {
  text-decoration: none;
}
.friend-box .caption .button .mat-flat-button {
  font-size: 14px;
}
@media (max-width: 574px) {
  .friend-box {
    margin-bottom: 15px;
  }
  .friend-box .caption {
    flex-wrap: wrap !important;
    padding: 0px 8px;
  }
  .friend-box .caption .button {
    width: 100%;
  }
  .friend-box .caption .button a, .friend-box .caption .button button {
    padding: 0 6px;
    min-width: 30px;
    line-height: 26px;
  }
}

.group-banner a:hover, .group-banner a:focus {
  text-decoration: none;
}
.group-banner .mat-stroked-button {
  border-radius: 0px;
  color: #2c2c2b;
}
.group-banner .mat-stroked-button .fas, .group-banner .mat-stroked-button .far {
  color: #3f165a;
  margin-right: 4px;
}

.pages-box .image {
  text-align: center;
}
.pages-box .caption {
  padding: 10px;
}
.pages-box .caption h4 {
  font-size: 16px;
  font-weight: bold;
}
.pages-box .caption .like {
  font-size: 14px;
  font-weight: 600;
  color: #2c2c2b;
  margin-bottom: 10px;
}
.pages-box .mat-stroked-button, .pages-box .mat-flat-button {
  min-width: 35px;
}

.event-box {
  border-bottom: 1px solid #d9d9d9;
}
.event-box .card-header {
  background-color: transparent;
  border-bottom-color: transparent;
}
.event-box .card-header .dropdown-menu {
  left: auto !important;
  right: 0px;
}
.event-box .card-header .profile {
  width: 40px;
  float: left;
  margin-right: 10px;
}
.event-box .card-header .caption {
  width: calc(100% - 50px);
  float: left;
}
.event-box .card-header .handing h5 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
}
.event-box .card-header .handing h5 a:hover {
  text-decoration: none;
}
.event-box .card-header .handing h5 span {
  color: #2c2c2b;
}
.event-box .card-header .time time {
  font-size: 13px;
  color: #a6a6a6;
}
.event-box .card-header .time .btn-link {
  padding: 0px 8px;
  font-size: 16px;
  line-height: 28px;
}
.event-box .card-header .time .btn-link:focus {
  text-decoration: none;
  outline: none;
}
.event-box .card-header .button .btn-link {
  padding-right: 0px;
  padding-top: 0px;
  font-size: 16px;
}
.event-box .card-header .button .btn-link:after {
  content: none;
}
.event-box .card-header .button .btn-link .fa {
  transform: scale(1.2);
}
.event-box .card-header .button .btn-link:focus {
  text-decoration: none;
  outline: none;
}
.event-box .caption2 {
  padding: 0rem 25px 0.65rem;
}
.event-box .caption2 .address {
  font-size: 13px;
  color: #a19d9d;
  margin-bottom: 5px;
}
.event-box .caption2 .address .fas {
  color: #3f165a;
  margin-right: 3px;
}
.event-box .caption2 .text {
  font-size: 14px;
  font-weight: 500;
}
.event-box .footer {
  padding: 0.65rem 25px;
}
.event-box .footer .mat-stroked-button {
  font-size: 13px;
  font-weight: 600;
  margin-right: 12px;
  border-color: transparent;
}
.event-box .footer .mat-stroked-button:focus {
  box-shadow: none;
}
.event-box .footer .mat-stroked-button .far {
  color: #3f165a;
  transform: scale(1.2);
  margin-right: 4px;
}

.photo-box {
  position: relative;
}
.photo-box .image {
  position: relative;
}
.photo-box .image a {
  cursor: pointer;
}
.photo-box .image .delete {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #fff;
  cursor: pointer;
}
.photo-box .image .delete:hover {
  color: #fff;
}
.photo-box .caption {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0 -105px 74px 33px rgba(0, 0, 0, 0.58);
}
.photo-box .comment-over {
  color: #fff;
}
.photo-box .comment-over [class*=border] {
  border-color: #fff !important;
}
.photo-box .comment-over h6 {
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 0px;
}
.photo-box .comment-over h6 a {
  color: #fff;
}
.photo-box .comment-over h6 a:hover {
  text-decoration: none;
}
.photo-box .comment-over .mat-stroked-button {
  font-size: 12px;
  line-height: 24px;
  padding: 0px 5px;
  width: 100%;
  text-align: left;
}
.photo-box .comment-over .mat-stroked-button .far, .photo-box .comment-over .mat-stroked-button .fas {
  margin-right: 3px;
}
.photo-box .comment-over .mat-stroked-button:hover {
  color: #fff;
}
.photo-box:hover .caption {
  opacity: 1;
}

.photo-row {
  margin-left: -8px;
  margin-right: -8px;
}
.photo-row [class*=col] {
  padding-left: 8px;
  padding-right: 8px;
}

.upload-priview .banner {
  position: relative;
  width: 160px;
}
.upload-priview .banner input {
  position: absolute;
  left: 0px;
  width: 160px;
  height: 160px;
  opacity: 0;
  cursor: pointer;
}
.upload-priview .banner .image-error {
  display: none;
}
.upload-priview .banner .ng-invalid ~ .image-error {
  display: block;
}
.upload-priview .img-view {
  margin-left: 15px;
  width: 160px;
  height: 160px;
}

.spinner-size {
  width: 40px !important;
  height: 40px !important;
}
.spinner-size svg {
  width: 40px !important;
  height: 40px !important;
}
.spinner-size.mat-primary circle {
  stroke: #3f165a;
}

.post-upload {
  display: flex;
  flex-wrap: wrap;
}
.post-upload .img-ul-file-upload {
  width: 160px;
  height: 160px;
  background: #fff url("/assets/img/upload-bg.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.post-upload .img-ul-file-upload .img-ul-button {
  margin: 0px;
  text-align: center;
  color: #0b2645;
  font-weight: bold;
  box-shadow: none;
  font-size: 1.75em;
  line-height: 36px;
  text-indent: -9999px;
  opacity: 0;
  height: 130px;
  width: 100%;
}
.post-upload .img-ul-container {
  padding: 0px !important;
  display: flex;
  align-items: center;
}
.post-upload .img-ul-container .img-ul-image {
  padding: 0 10px;
  border: 1px solid #d9d9d9;
  width: 140px;
}

.news-box {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.news-box .image {
  width: 100%;
}
.news-box .caption {
  padding: 10px 20px;
}
.news-box .caption a {
  color: #0b2645;
}
.news-box .caption a:hover {
  color: #3f165a;
}
.news-box .caption h6 {
  padding-bottom: 6px;
  text-decoration: underline;
  font-size: 15px;
}
.news-box .caption h4 {
  font-size: 18px;
  font-weight: 600;
}

.blog-box {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.blog-box .image {
  width: 100%;
}
.blog-box .caption {
  padding: 10px 20px;
}
.blog-box .caption h3 {
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 4px;
}
.blog-box .caption h3 a {
  color: #0b2645;
}
.blog-box .caption h3 a:hover {
  color: #3f165a;
}
.blog-box .caption time {
  font-size: 13px;
  color: #2c2c2b;
  margin-bottom: 1px;
}
.blog-box .caption .text {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}
.blog-box .caption .action {
  width: -100%;
}

.blog-popular .card-header {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
  border-bottom: none;
}
.blog-popular ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.blog-popular ul li {
  margin-bottom: 30px;
  padding-bottom: 25px;
  position: relative;
}
.blog-popular ul li:last-child {
  border-bottom: none !important;
  padding-bottom: 0px;
}
.blog-popular ul li:first-child .image {
  max-width: 100%;
  width: 100%;
}
.blog-popular ul li:first-child .image:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.blog-popular ul li:first-child .caption {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: calc(100% - 70px);
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  border: 5px solid #fff;
  z-index: 9;
  padding: 15px;
}
.blog-popular ul li:first-child .caption h6 {
  color: #fff;
}
.blog-popular ul li:first-child .caption h4 {
  color: #fff;
  text-align: center;
}
.blog-popular ul li .image {
  position: relative;
  max-width: 200px;
}
.blog-popular ul li .image .count {
  color: #fff;
  background-color: #3f165a;
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  left: 15px;
  z-index: 6;
}
.blog-popular ul li .caption {
  padding: 0px 15px;
}
.blog-popular ul li .caption h6 {
  font-size: 14px;
  color: #2c2c2b;
  margin-bottom: 6px;
}
.blog-popular ul li .caption h4 {
  font-size: 18px;
  color: #0b2645;
  font-weight: bold;
}
.blog-popular ul li .caption h4 a {
  color: inherit;
}
.blog-popular ul li .caption h4 a:hover {
  color: #3f165a;
  text-decoration: none;
}
@media (max-width: 574px) {
  .blog-popular ul li {
    margin-bottom: 15px;
  }
}

.dropdown-notification .scrolldiv .request {
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dropdown-notification .dropdown-toggle:after {
  content: none;
}
.dropdown-notification .dropdown-menu {
  min-width: 360px;
  margin-top: 8px;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  left: auto !important;
  right: 0px;
}
.dropdown-notification .dropdown-menu .dropdown-arrow {
  position: absolute;
  bottom: 100%;
  left: auto;
  right: -8px;
  overflow: hidden;
  height: 22px;
  width: 50px;
}
.dropdown-notification .dropdown-menu .dropdown-arrow:after {
  content: "";
  transform: rotate(45deg);
  background: #fff;
  position: absolute;
  top: 16px;
  right: 15px;
  width: 25px;
  height: 25px;
  border-radius: 0.25rem;
}
.dropdown-notification .dropdown-menu .dropdown-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0px 0px 6px;
  line-height: 22px;
}
.dropdown-notification .dropdown-menu .dropdown-header .font-18 {
  font-size: 18px;
}
.dropdown-notification .timeline {
  position: relative;
  padding: 0;
  list-style: none;
  padding-left: 10px;
}
.dropdown-notification .timeline:before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 10px;
  background-color: #ebedf2;
}
.dropdown-notification .timeline.scroller {
  max-height: 200px;
  min-height: 200px;
}
.dropdown-notification .timeline .scroll {
  height: 100%;
  overflow: auto;
}
.dropdown-notification .timeline .timeline-item {
  position: relative;
  padding: 6px 15px 6px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  line-height: 19px;
}
.dropdown-notification .timeline .timeline-item a {
  color: inherit;
  width: 100%;
}
.dropdown-notification .timeline .timeline-item a:hover {
  text-decoration: none;
}
.dropdown-notification .timeline .timeline-item .timeline-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 6px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  background-color: transparent;
}
.dropdown-notification .request {
  position: relative;
  padding: 0;
  list-style: none;
  padding-left: 0px;
}
.dropdown-notification .request .friend-box {
  margin: 0px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
}
.dropdown-notification .request .friend-box .image img {
  height: 55px;
}
.dropdown-notification .request .friend-box .button a {
  font-size: 12px;
  line-height: 28px;
  padding: 0 6px;
  min-width: 45px;
}
@media (max-width: 419px) {
  .dropdown-notification .dropdown-menu {
    min-width: 320px;
    right: -60px;
  }
  .dropdown-notification .dropdown-menu .dropdown-arrow {
    right: 49px;
  }
  .dropdown-notification app-notification .dropdown-menu {
    right: -112px;
  }
  .dropdown-notification app-notification .dropdown-menu .dropdown-arrow {
    right: 95px;
  }
}

.mat-tab-label .mat-tab-label-content {
  width: 100%;
}

.mat-tab-label {
  padding: 0px !important;
}
.mat-tab-label a {
  padding: 0 24px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.popup-dialog {
  height: calc(100% - 0px);
  width: 100% !important;
  max-width: 100% !important;
}
.popup-dialog .mat-dialog-container {
  border-radius: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: transparent;
}
.popup-dialog app-image-popup {
  width: 100%;
  position: relative;
  height: 100%;
  float: left;
}
@media (max-width: 767px) {
  .popup-dialog .mat-dialog-container {
    overflow: scroll;
  }
  .popup-dialog app-image-popup {
    height: 100vh;
  }
}

.images-box {
  width: 100%;
  height: 100%;
  float: left;
}
.images-box .image {
  width: calc(100% - 380px);
  float: left;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
}
.images-box .image:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
.images-box .image .btn {
  position: absolute;
  top: 48%;
  color: #fff;
  font-size: 20px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.images-box .image .btn i {
  transform: scale(1.5);
}
.images-box .image video {
  width: 100%;
}
.images-box .image .btn-prev {
  left: 15px;
}
.images-box .image .btn-next {
  right: 15px;
}
.images-box .image .btn-close {
  top: 15px;
  right: 15px;
}
.images-box .image:hover:before {
  background-color: rgba(0, 0, 0, 0.3);
}
.images-box .image:hover .btn {
  opacity: 1;
}
.images-box .comment {
  width: 380px;
  height: 100%;
  float: left;
  background-color: #fff;
}
.images-box .comment .timeline-post {
  margin-bottom: 0px;
  height: 100%;
  padding-bottom: 80px;
}
.images-box .comment .comment-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.images-box .comment .comment-list::-webkit-scrollbar {
  width: 10px;
}
.images-box .comment .comment-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.images-box .comment .comment-list::-webkit-scrollbar-thumb {
  background: #888;
}
.images-box .comment .comment-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.images-box .comment app-post-comment-form {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding-top: 4px;
  background-color: #fff;
}
.images-box .comment app-post-comment-form form .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.images-box .comment app-post-comment-form form .profile {
  width: 40px;
}
.images-box .comment app-post-comment-form form .caption {
  width: calc(100% - 40px);
}
@media (max-width: 767px) {
  .images-box {
    flex-wrap: wrap;
  }
  .images-box .image {
    width: 100%;
    height: 320px;
  }
  .images-box .image .btn {
    opacity: 1;
  }
  .images-box .comment {
    width: 100%;
    height: calc(100% - 322px);
  }
  .images-box app-post-comment-form .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0px;
  }
  .images-box app-post-comment-form textarea.mat-input-element {
    min-height: 55px;
  }
}
@media (max-width: 574px) {
  .images-box .image {
    height: 220px;
  }
  .images-box .comment {
    height: calc(100% - 222px);
  }
}

.edit-posts {
  padding: 0px !important;
}
.edit-posts .new-post {
  margin-bottom: 0px;
  border: none;
}

@keyframes n {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  to {
    opacity: 0.3;
  }
}
.load-1 {
  height: 100%;
  position: static;
  width: 100%;
  z-index: 1;
  /*@media only screen and (max-width: 1023px) {
    display: block; position: relative; z-index: 1001; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden; height: calc(100vh - 80px); background-color: $white;
  }*/
}
.load-1 .L1 {
  padding: 20px 15px 20px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 47, 52, 0.2);
  background: #fff;
  display: flex;
}
.load-1 .L1 .L3 {
  height: 24px;
  background-color: #dcdcdc;
  margin: 10px 5px 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: n;
  animation-timing-function: linear;
  background: rgba(0, 47, 52, 0.2);
  background-size: 900px 104px;
}
.load-1 .L1 .L3,
.load-1 .L1 .L2 {
  display: inline-block;
  position: relative;
}
.load-1 .L1 .L2 {
  padding-right: 8px;
  width: 62px;
  vertical-align: top;
  float: left;
}
.load-1 .L1 .L5 {
  width: calc(100% - 62px);
  display: inline-block;
  padding-left: 2%;
}
.load-1 .L1 .L7 {
  padding: 5px 0;
  color: #002f34;
}
.load-1 .L1 .L7,
.load-1 .L1 .ZnqJl {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.load-1 .L1 .ZnqJl {
  margin-top: 2px;
  color: rgba(0, 47, 52, 0.36);
}
.load-1 .L1 .L1w {
  clear: both;
}
.load-1 .L1 .L4 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
}
.load-1 .L1 .L6 {
  height: 16px;
  display: block;
}
.load-1 .L1 .ntTy4 {
  height: 12px;
  display: block;
}
.load-1 .L1 .L3w {
  height: 10px;
  display: block;
}

.input-group-search {
  position: relative;
}
.input-group-search .form-control {
  border-color: #ddd;
  height: 45px;
  padding-left: 35px;
  padding-right: 25px;
}
.input-group-search .input-group-text {
  position: absolute;
  z-index: 10;
  background-color: transparent;
  border: none;
  left: -2px;
  top: 10px;
}
.input-group-search .close {
  position: absolute;
  z-index: 10;
  background-color: transparent;
  border: none;
  right: 8px;
  top: 12px;
  font-size: 20px;
}

.friends-privacy {
  width: 100%;
  float: left;
  position: relative;
}
.friends-privacy .input-group {
  position: relative;
}
.friends-privacy .input-group .form-control {
  border-color: #ddd;
  height: 45px;
  padding-left: 35px;
  padding-right: 25px;
}
.friends-privacy .input-group .input-group-text {
  position: absolute;
  z-index: 10;
  background-color: transparent;
  border: none;
  left: -2px;
  top: 10px;
}
.friends-privacy .input-group .close {
  position: absolute;
  z-index: 10;
  background-color: transparent;
  border: none;
  right: 8px;
  top: 12px;
  font-size: 20px;
}
.friends-privacy .scroll-inner {
  margin-left: -8px;
  margin-right: -8px;
}
.friends-privacy .mat-checkbox {
  width: 100%;
  float: left;
  padding: 5px 15px;
  cursor: default;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}
.friends-privacy .mat-checkbox:hover {
  border-top-color: #ddd;
  border-bottom-color: #ddd;
  background-color: #eee;
}
.friends-privacy .mat-checkbox-layout {
  width: 100%;
  margin-bottom: 0px;
}
.friends-privacy .mat-checkbox-label {
  width: calc(100% - 28px);
}
.friends-privacy .profile {
  width: 100%;
}
.friends-privacy .profile .image {
  width: 60px;
}
.friends-privacy .profile .caption {
  width: calc(100% - 60px);
  padding-left: 15px;
}
.friends-privacy .profile h5 {
  font-size: 15px;
  margin-bottom: 1px;
}
.friends-privacy .loadings {
  position: relative;
}
.friends-privacy .all-list + .loadings, .friends-privacy .all-list ~ .loadings {
  margin-top: -27px;
}

.announcement .text p:last-child {
  margin-bottom: 0px;
}

app-add-photo .card.new-post {
  margin-bottom: 0px;
  border: none;
}
app-add-photo .card.new-post .button {
  padding-top: 12px;
  position: relative;
}

.like-user-list.mat-dialog-content {
  padding: 0px;
  position: relative;
  min-height: 85vh;
  padding-top: 50px;
  overflow: hidden;
}
.like-user-list .close-button {
  position: absolute;
  right: 4px;
  top: -44px;
  z-index: 9;
}
.like-user-list .mat-tab-label {
  min-width: 60px;
}
.like-user-list .model-inner {
  position: relative;
  width: 100%;
}
.like-user-list .mat-tab-header {
  position: absolute;
  left: 0px;
  top: -50px;
  width: 100%;
}
.like-user-list .mat-tab-body-wrapper {
  min-height: 76vh;
  overflow: initial;
}
.like-user-list .mat-tab-body {
  overflow: inherit !important;
}
.like-user-list .mat-tab-body-content {
  overflow: inherit;
}
.like-user-list .user-list {
  width: 100%;
  height: 100%;
  min-height: 76vh;
  max-height: 76vh;
  overflow: auto;
}

.announcement .owl-prev {
  position: absolute;
  left: -4px;
  top: 50%;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
.announcement .owl-next {
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
.announcement button {
  position: absolute;
  z-index: 2;
  right: 4px;
  top: -11px;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
.announcement:hover .owl-prev {
  opacity: 1;
}
.announcement:hover .owl-next {
  opacity: 1;
}
.announcement:hover button {
  opacity: 1;
}

.css-scroll {
  scrollbar-color: #3f165a #d9d9d9;
  scrollbar-width: thin;
}
.css-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #d9d9d9;
}
.css-scroll::-webkit-scrollbar-thumb {
  background-color: #3f165a;
  border-radius: 10px;
}
.css-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #3f165a;
  border: 1px solid #3f165a;
}
.css-scroll::-webkit-scrollbar-thumb:active {
  background-color: #3f165a;
  border: 1px solid #3f165a;
}

ngx-stripe-card {
  width: 100%;
  float: left;
  border: 1px solid #ddd;
  padding: 9px 15px;
  border-radius: 4px;
}

.title-count .material-icons {
  font-family: "Muli", sans-serif;
  font-size: 13px;
  padding-top: 4px;
  float: right;
  width: 38px;
}
.title-count.www .material-icons {
  font-size: 18px;
  width: 58px;
  padding-top: 0px;
  color: #666;
}

.paid-icon {
  position: relative;
}
.paid-icon:before {
  content: "";
  position: absolute;
  background-image: url(/assets/img/Paid.png);
  background-size: 30px 30px;
  display: inline-block;
  width: 30px;
  height: 30px;
  top: 0;
  background-repeat: no-repeat;
  z-index: 1;
  left: 0;
}

.announcement .iframe iframe {
  pointer-events: none;
  width: 100%;
}

app-announcement-video iframe {
  width: 100%;
  margin-bottom: -23px;
}

.cdk-global-scrollblock {
  position: static;
}

.timeline-backdrop {
  pointer-events: inherit;
}

.cropper-dialog button:focus {
  outline: none;
}
.cropper-dialog .mat-dialog-container {
  position: relative;
}
.cropper-dialog .dialog-close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.cropper-dialog .mat-dialog-content {
  min-height: 200px;
  max-height: 80vh;
}
.cropper-dialog .uploder {
  text-align: center;
  padding: 10px;
}
.cropper-dialog .buttons {
  margin-left: -2px;
  margin-right: -2px;
}
.cropper-dialog .buttons .btn {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
}

.live-dialog .close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.live-dialog .mat-dialog-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  font-family: "Muli", sans-serif;
  line-height: 26px;
}

.text-light {
  color: #666 !important;
}

.all-content {
  width: 100%;
  float: left;
  position: relative;
  min-height: calc(77vh - 11px);
  background-color: #e9ebee;
}

.main-wrap {
  width: 100%;
  float: left;
  position: relative;
  min-height: calc(82vh - 16px);
  margin-top: 77px;
  background-color: #e9ebee;
}
@media (max-width: 1199px) {
  .main-wrap {
    margin-top: 68px;
  }
}

.inner-page {
  width: 100%;
  float: left;
  position: relative;
  padding: 30px 0px;
}

body.embaded-post app-header, body.embaded-post app-footer, body.embaded-post app-landing-navbar {
  display: none;
}
body.embaded-post .main-wrap {
  margin-top: 0px !important;
}
body.embaded-post .timeline-post {
  margin-bottom: 0px;
}

read-more {
  white-space: break-spaces;
}

header#landing-header {
  width: 100%;
  float: left;
  position: relative;
  background-color: #3f165a;
  background-image: linear-gradient(#5c2083, #3f165a);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  padding: 6px 0px;
}
header#landing-header .logo {
  padding: 6px 0px;
  float: left;
  width: 100%;
}
header#landing-header form.login {
  color: #fff;
}
header#landing-header form.login label {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
}
header#landing-header form.login .form-group {
  margin-bottom: 20px;
  padding-right: 15px;
  position: relative;
}
header#landing-header form.login .btn {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 9px 25px;
}
header#landing-header form.login .invalid-feedback {
  margin-bottom: 0px;
  position: absolute;
  margin-top: 0px;
  color: #fff;
}
header#landing-header form.login .invalid-feedback a {
  color: #fff;
}
header#landing-header .navbar-menu {
  list-style: none;
  margin-bottom: 0px;
  padding: 0px;
}
header#landing-header .navbar-menu .nav-link {
  color: #fff;
  font-size: 14px;
  padding: 0.5rem 0.4rem;
  position: relative;
}
header#landing-header .navbar-menu .nav-link .fas {
  font-size: 30px;
}
header#landing-header .navbar-menu .nav-link:focus {
  outline: none;
}
header#landing-header .navbar-menu .nav-link .badge {
  position: absolute;
  right: 12px;
  top: 0px;
}
header#landing-header .navbar-menu li.profile {
  padding-left: 0px;
}
header#landing-header .navbar-menu li.profile .nav-link > img {
  margin-right: 8px;
}
header#landing-header .navbar-menu li.profile .dropdown-menu {
  left: auto !important;
  right: 0px;
}
header#landing-header .search {
  max-width: 425px;
}
header#landing-header .search .input-group .input-group-append {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 9;
}
header#landing-header .search .input-group .input-group-text {
  background-color: transparent;
  border: none;
  color: #fff;
}
header#landing-header .search .input-group .form-control {
  background-color: transparent;
  border-radius: 0px;
  padding-left: 10px;
  font-size: 14px;
  height: calc(1.5em + 0.75rem + 6px);
  border-color: rgba(255, 255, 255, 0.4);
  color: #fff;
}
header#landing-header .search .input-group .form-control::placeholder {
  color: #fff;
}
header#landing-header .search .input-group .btn {
  font-size: 18px;
  font-weight: bold;
  padding: 5px 10px;
  color: #fff;
}
header#landing-header.fixed {
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 99;
}
@media (max-width: 991px) {
  header#landing-header form .form-group {
    width: 200px;
  }
}
@media (max-width: 767px) {
  header#landing-header .logo {
    text-align: center;
  }
  header#landing-header .logo img {
    width: 100px;
  }
}
@media (max-width: 574px) {
  header#landing-header form .form-group {
    width: 100%;
    margin-bottom: 5px !important;
  }
  header#landing-header form .form-group.login-button {
    margin-top: 22px !important;
  }
  header#landing-header form .invalid-feedback {
    position: static;
  }
  header#landing-header form .form-group.w-auto label {
    display: none;
  }
  header#landing-header .navbar-menu .nav-link .badge {
    right: 0px;
  }
}

.loggedIn {
  width: 100%;
  float: left;
}
.loggedIn .mobile-search {
  display: none;
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .loggedIn header#landing-header .navbar-menu li.profile {
    padding-left: 0px;
  }
  .loggedIn header#landing-header .navbar-menu .nav-link {
    padding: 0.5rem 0.2rem;
  }
  .loggedIn header#landing-header .search {
    padding: 0px;
  }
}
@media (max-width: 1199px) {
  .loggedIn .container > .row .col-lg-2.col-md-3.col-12 {
    flex: 0 0 130px;
    max-width: 130px;
  }
  .loggedIn .container > .row .col-lg-10.col-md-9.col-12 {
    flex: 0 0 calc(100% - 130px);
    max-width: calc(100% - 130px);
  }
}
@media (max-width: 767px) {
  .loggedIn .mobile-search {
    display: block;
  }
  .loggedIn header#landing-header .navbar-menu li.profile {
    padding-left: 0px;
  }
  .loggedIn header#landing-header .navbar-menu li.profile span {
    display: none;
  }
  .loggedIn header#landing-header .navbar-menu li.profile .dropdown-toggle:after {
    display: none;
  }
  .loggedIn header#landing-header .navbar-menu .nav-link {
    padding: 0.5rem 0.2rem;
  }
  .loggedIn header#landing-header .search {
    position: fixed;
    width: 130%;
    left: 0px;
    top: 0px;
    padding: 14px 15px;
    z-index: 9999;
    max-width: 100%;
    background-color: #3f165a;
    background-image: linear-gradient(#5c2083, #3f165a);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  }
  .loggedIn header#landing-header .search.notshow {
    display: none;
  }
  .loggedIn header#landing-header .search.show {
    display: block;
  }
}
@media (max-width: 410px) {
  .loggedIn .container > .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .loggedIn .container > .row [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .loggedIn header#landing-header .navbar-menu .nav-link .fas {
    font-size: 18px;
  }
}

nav.landing-navbar {
  width: 100%;
  float: left;
  position: relative;
  background-color: #d9d9d9;
  padding: 0px 15px;
}
nav.landing-navbar .nav-link {
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  padding: 0.5rem 1.3rem;
  color: #2c2c2b;
  transition: all 0.3s ease-in-out;
}
nav.landing-navbar .nav-link:hover, nav.landing-navbar .nav-link.active {
  background-color: #f2e6f9;
  color: #3f165a;
}
@media (max-width: 540px) {
  nav.landing-navbar .nav-link {
    font-size: 12px;
    padding: 0.5rem 0.45rem;
  }
}

.landing-content {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 40px;
  background-color: #fff;
}
.landing-content h2 {
  font-size: 30px;
  font-weight: bold;
  color: #2c2c2b;
  padding-bottom: 25px;
}
.landing-content form .form-group {
  position: relative;
  margin-bottom: 33px;
}
.landing-content form .form-group > label {
  position: absolute;
  margin: 0px;
  color: #2c2c2b;
  top: -13px;
  left: 15px;
  background-color: #fff;
  border-radius: 6px;
  border-color: #d9d9d9;
  padding: 0px 4px;
}
.landing-content form .form-group .form-control {
  background-color: transparent;
  height: 55px;
  color: #2c2c2b;
  border-color: #c7c7c7;
}
.landing-content form .form-group textarea.form-control {
  height: 90px;
}
.landing-content form .btn {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 30px;
}
.landing-content .text {
  font-size: 13px;
  font-weight: 600;
  color: #5e5e5d;
  margin-bottom: 50px;
  margin-top: 30px;
}
@media (max-width: 574px) {
  .landing-content {
    padding-top: 20px;
  }
  .landing-content h2 {
    font-size: 24px;
  }
  .landing-content .text {
    margin-bottom: 25px;
  }
}

footer#footer {
  width: 100%;
  float: left;
  position: relative;
  background-color: #d9d9d9;
  border-top: 1px solid #c7c7c7;
  padding: 35px 0px;
}
footer#footer .footer-menu {
  list-style: none;
  padding: 0px;
  margin: 0px 40px 0px;
  font-size: 14px;
  font-weight: 600;
}
footer#footer .footer-menu li {
  color: #6c757d;
}
footer#footer .footer-menu li + li:before {
  content: " |";
  padding: 0px 10px;
}
footer#footer .footer-menu li a {
  color: inherit;
  transition: all 0.3s ease-in-out;
}
footer#footer .footer-menu li a:hover {
  color: #3f165a;
  text-decoration: none;
}
footer#footer .copyright {
  font-size: 14px;
  text-align: right;
  color: #6c757d;
}
footer#footer .copyright a {
  color: inherit;
}
@media (max-width: 991px) {
  footer#footer {
    padding: 15px 0px;
  }
  footer#footer .footer-menu {
    margin: 0px;
    justify-content: center;
    width: 100%;
  }
  footer#footer .app {
    text-align: center;
    margin-top: 15px;
    padding: 0px;
  }
  footer#footer .copyright {
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }
}

.update-profile {
  margin: 30px 0px;
}
@media (max-width: 574px) {
  .update-profile {
    margin: 15px 0px;
  }
}

.user-setting {
  margin: 30px 0px;
}
@media (max-width: 574px) {
  .user-setting {
    margin: 15px 0px;
  }
}

.user-about {
  margin: 30px 0px;
  font-size: 14px;
  font-weight: 600;
  color: #3f3f40;
}
.user-about h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 574px) {
  .user-about {
    margin: 15px 0px;
  }
}

.group-page {
  background-color: #e9ebee;
  padding: 20px 0px;
}
.group-page .content {
  position: relative;
}
.group-page .mat-tab-group {
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
.group-page .mat-tab-body-wrapper {
  padding: 30px 10px;
}
.group-page .mat-tab-body-content {
  padding: 0px 15px;
}
.group-page .create .mat-flat-button {
  position: absolute;
  right: 30px;
  top: 7px;
  z-index: 4;
}
.group-page .create .mat-flat-button:hover, .group-page .create .mat-flat-button:focus {
  text-decoration: none;
}
.group-page .group-link-page {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-bottom: 20px;
}
.group-page .group-link-page .mat-tab-body-wrapper {
  padding: 0px !important;
}
.group-page .group-link-page .mat-tab-group {
  border: none !important;
}
.group-page .group-link-page .mat-tab-group a {
  color: rgba(0, 0, 0, 0.87);
}
.group-page .allgrpu-load {
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .group-page .group-link-page .mat-tab-group {
    padding-right: 120px;
  }
  .group-page .create .mat-flat-button {
    right: 18px;
    top: 10px;
    min-width: 30px;
    padding: 0px 10px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  .group-page .group-link-page {
    margin-top: 20px;
  }
  .group-page .group-link-page .mat-tab-group {
    padding-right: 0px;
  }
  .group-page .create .mat-flat-button {
    right: 18px;
    top: -16px;
    min-width: 30px;
    padding: 0px 10px;
    line-height: 32px;
  }
}

.group-view .info h5 {
  font-size: 16px;
  margin-bottom: 3px;
  font-weight: bold;
}
.group-view .info p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}

.event-about .details {
  font-size: 13px;
  font-weight: 600;
}
.event-about .details .far, .event-about .details .fas {
  width: 18px;
  transform: scale(1.2);
  color: #3f165a;
}
.event-about .text {
  font-size: 14px;
  font-weight: 600;
}
.event-about .text h6 {
  margin-bottom: 4px;
  font-weight: bold;
}

.user-setting .block-user {
  list-style: none;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
}
.user-setting .block-user .mat-stroked-button {
  border: none;
  color: #3f165a;
}
.user-setting .block-user .mat-stroked-button:focus {
  outline: none;
}
.user-setting .block-user li {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
}
.user-setting .block-user li span {
  width: 120px;
}

.news-page {
  padding: 15px 0 0 0;
}
.news-page .livetxt {
  font-size: 12px;
  font-weight: 700;
  color: #e32020;
  padding: 5px 0;
}
.news-page .card-one {
  padding: 0 30px 0 0;
}
.news-page .card-one .content time {
  color: #a19d9d !important;
  margin: 0 0 10px 0;
  display: block;
}
.news-page .card-one .chatbox {
  border: 1px solid #cccccc;
  margin: 15px 0 15px 0;
  min-height: 600px;
}
.news-page .card-one .chatbox .chabox-top ul {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  list-style: none;
}
.news-page .card-one .chatbox .chabox-top ul li {
  border-bottom: 1px solid #cccccc;
  padding: 12px;
}
.news-page .card-one .chatbox .chabox-top ul li .image {
  text-align: center;
  width: 40px;
  margin: 0 22px 0 0;
  float: left;
}
.news-page .card-one .chatbox .chabox-top ul li .image img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}
.news-page .card-one .chatbox .chabox-top ul li .image .name {
  font-size: 14px;
  color: #494a4b;
  text-align: center;
}
.news-page .card-one .chatbox .chabox-top ul li .comment {
  font-size: 14px;
  color: #494a4b;
  text-align: left;
}
.news-page .card-one .chatbox .chatbox-bottom {
  padding: 18px;
}
.news-page .card-one .chatbox .chatbox-bottom .form-control {
  font-size: 14px;
  border: 1px solid #cccccc;
  height: 85px;
  border-radius: 0px;
  box-shadow: none;
  color: #494a4b;
}
.news-page .card-one .chatbox .chatbox-bottom .smileicon {
  float: left;
  margin: 7px 0 0 0;
}
.news-page .card-one .chatbox .chatbox-bottom .smileicon a {
  margin: 0 7px 0 0;
}
.news-page .card-one .chatbox .chatbox-bottom .btndiv {
  margin: 28px 0 0px 0;
  text-align: right;
}
.news-page .card-one .chatbox .chatbox-bottom .btndiv .btn-primary {
  color: #ffffff;
  line-height: 35px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 17px;
  box-shadow: none;
}
.news-page .card-two ul.item li a {
  font-size: 14px !important;
  font-weight: 700 !important;
}
.news-page .card-two ul.item.word-news li:first-child a {
  font-size: 20px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}
.news-page .card-two ul.item.word-news li:first-child a span {
  text-decoration: underline;
}
.news-page .card-two ul.item.word-news li:first-child a.sports_readmore {
  font-size: 14px !important;
  font-weight: 700;
  font-style: italic;
}
.news-page .card-two ul.item.word-news li:first-child img {
  height: 195px;
  object-fit: cover;
  object-position: center;
}
.news-page .card-two ul.item.word-news li {
  min-height: 55px;
}
.news-page .card-two ul.item.word-news li img {
  height: 55px;
  object-fit: cover;
  object-position: center;
}
.news-page .card-two .ctn-new-list p {
  font-size: 14px;
  margin: 5px 0 0 0;
  color: #5e5e5d !important;
  text-decoration: none !important;
}
.news-page .politics {
  padding-top: 10px;
  padding-bottom: 10px;
}
.news-page .politics ul.item li a {
  font-size: 14px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}
.news-page .politics ul.item.politics li:first-child a {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.news-page .politics ul.item.politics li:first-child a span {
  text-decoration: underline;
}
.news-page .politics ul.item.politics li:first-child a.sports_readmore {
  font-size: 14px !important;
  font-weight: 700;
  font-style: italic;
}
.news-page .politics ul.item.politics li:first-child img {
  height: 195px;
  object-fit: cover;
  object-position: center;
}
.news-page .politics ul.item.politics li {
  min-height: 55px;
}
.news-page .politics ul.item.politics li img {
  height: 55px;
  object-fit: cover;
  object-position: center;
}
.news-page .politics .ctn-new-list p {
  font-size: 14px;
  margin: 5px 0 0 0;
  color: #5e5e5d !important;
}
.news-page .entertainmentbox {
  padding-top: 10px;
  padding-bottom: 10px;
}
.news-page .entertainmentbox ul.item li a {
  font-size: 14px !important;
  font-weight: 700 !important;
}
.news-page .entertainmentbox ul.item.entertainment li:first-child a {
  font-size: 20px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}
.news-page .entertainmentbox ul.item.entertainment li:first-child a span {
  text-decoration: underline;
}
.news-page .entertainmentbox ul.item.entertainment li:first-child a.sports_readmore {
  font-size: 14px !important;
  font-weight: 700;
  font-style: italic;
}
.news-page .entertainmentbox ul.item.entertainment li:first-child img {
  height: 195px;
  object-fit: cover;
  object-position: center;
}
.news-page .entertainmentbox ul.item.entertainment li {
  min-height: 55px;
}
.news-page .entertainmentbox ul.item.entertainment li img {
  height: 55px;
  object-fit: cover;
  object-position: center;
}
.news-page .entertainmentbox .ctn-new-list p {
  font-size: 14px;
  margin: 5px 0 0 0;
  color: #5e5e5d !important;
}
.news-page .travelinfluencer {
  padding-top: 10px;
  padding-bottom: 10px;
}
.news-page .travelinfluencer ul.item li p {
  display: block !important;
}
.news-page .travelinfluencer ul.item li .sports_readmore {
  display: block !important;
}
.news-page .travelinfluencer ul.item li {
  width: 33.3333%;
  display: inline-block !important;
  vertical-align: top;
}
.news-page .travelinfluencer ul.item li span {
  font-size: 14px;
  font-weight: 700;
}
.news-page .travelinfluencer ul.item li p {
  display: none !important;
}
.news-page .travelinfluencer ul.item li .sports_readmore {
  display: none !important;
}
.news-page .travelinfluencer ul.item li img {
  height: 55px;
  object-fit: cover;
  object-position: center;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1), .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) {
  width: 50%;
  float: left;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) img, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) img {
  width: 100%;
  max-width: 100% !important;
  height: 280px;
  object-position: center;
  object-fit: cover;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) .ctn-news-img, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) .ctn-news-img {
  float: none !important;
  width: 100%;
  max-width: 100% !important;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) .ctn-news-img img, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) .ctn-news-img img {
  max-width: 100% !important;
  height: 280px;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) .detail, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) .detail {
  background: #fff;
  box-shadow: 0 0 5px #cccccc;
  max-width: 390px;
  height: 198px;
  margin: -115px auto 35px auto;
  padding: 18px 18px;
  position: relative;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) .detail span, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) .detail span {
  display: block;
  font-size: 16px;
  font-weight: 900;
  color: #000;
  margin: 0 0 5px 0;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) .detail p, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) .detail p {
  font-size: 14px;
  color: #5e5e5d !important;
  margin: 0 0 5px 0;
  display: inline-block !important;
}
.news-page .travelinfluencer ul.item.lifestyle li:nth-child(1) .detail .sports_readmore, .news-page .travelinfluencer ul.item.lifestyle li:nth-child(2) .detail .sports_readmore {
  font-size: 14px;
  color: 2c2c2b;
  display: inline-block !important;
}
.news-page .sportsbox {
  padding-top: 10px;
  padding-bottom: 10px;
}
.news-page .subscribe .content {
  font-size: 14px;
  color: #5e5e5d;
  margin: -15px 0 15px 0;
}
.news-page .subscribe .form-control {
  font-size: 15px;
  border: 1px solid #cccccc;
  height: 55px;
  border-radius: 4px;
  box-shadow: none;
  color: #575656;
}
.news-page .subscribe label {
  font-size: 14px;
  font-weight: 400;
  padding: 0 10px;
  margin: 0 0 -14px 5px;
  color: #2c2c2b;
  background: #fff;
  float: left;
}
.news-page .subscribe .form-group {
  margin-bottom: 25px;
}
.news-page .subscribe .form-group-1 {
  margin-bottom: 15px;
}
.news-page .subscribe .btndiv .btn-primary {
  color: #ffffff;
  line-height: 55px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 35px;
  box-shadow: none;
}
.news-page .mat-tab-label {
  padding: 0 6px;
  min-width: 100px;
}
.news-page .mat-tab-body-content {
  padding: 15px 0px;
}
.news-page .page-card {
  margin-bottom: 30px;
  position: relative;
}
.news-page .page-card .card-header {
  margin-bottom: 15px;
  padding: 0px;
}
.news-page .page-card .card-header h3 span {
  padding: 10px 18px;
  float: left;
  font-size: 16px;
}
.news-page .page-card.card-black .card-header {
  border-bottom-color: #000;
}
.news-page .page-card.card-danger .card-header {
  border-bottom-color: #db1c24;
}
.news-page .page-card.card-success .card-header {
  border-bottom-color: #60c31f;
}
.news-page .page-card.card-info .card-header {
  border-bottom-color: #2678d2;
}
.news-page .page-card.card-story .card-header {
  margin-bottom: 0px;
  padding: 0.65rem 15px;
  margin-top: 10px;
}
.news-page .page-card.card-story .card-header h3 {
  font-size: 14px;
  font-weight: bold;
}
.news-page .page-card.card-story .card-body {
  padding: 1rem 12px;
}
.news-page .page-card.card-story .owl-theme .owl-nav {
  top: -50px;
  right: 0px;
}
.news-page .owl-theme .owl-nav {
  margin: 0px;
  position: absolute;
}
.news-page .owl-theme .owl-nav [class*=owl-] {
  background-color: transparent;
  color: #6c757d;
  margin: 0px;
  font-size: 18px;
  padding: 4px 4px;
}
.news-page .owl-theme .owl-nav [class*=owl-]:hover {
  color: #3f165a;
}
.news-page .card.feature .owl-carousel {
  padding-bottom: 20px;
}
.news-page .card.feature .owl-theme .owl-nav {
  bottom: -15px;
  right: 0px;
}
.news-page .top-arrow .owl-theme .owl-nav {
  top: -50px;
  right: 0px;
}

.videos-page .view-box .caption .count-group {
  padding: 10px 0px;
  font-size: 13px;
}
.videos-page .view-box .caption .content {
  padding: 15px 0px 15px;
}
.videos-page .view-box .caption .content .text {
  display: none;
}
.videos-page .view-box .caption .content time {
  font-size: 13px;
  color: #a19d9d;
}

.chat-user-list {
  padding: 0px 0px 0px 0px;
  margin: 0px;
  list-style: none;
}
.chat-user-list li {
  border-bottom: 1px solid #d9d9d9;
  position: relative;
}
.chat-user-list li:hover .action a {
  opacity: 1;
}
.chat-user-list li .action {
  position: absolute;
  right: 2px;
  z-index: 4;
  top: -4px;
}
.chat-user-list li .action a {
  opacity: 0;
  cursor: pointer;
}
.chat-user-list li .action a[aria-expanded=true] {
  opacity: 1;
}
.chat-user-list li .count {
  color: #fff;
  background-color: #3f165a;
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  right: 6px;
  z-index: 6;
}
.chat-user-list li .img {
  width: 50px;
  margin-right: 10px;
}
.chat-user-list li .caption {
  width: calc(100% - 60px);
}
.chat-user-list li .text {
  font-size: 14px;
  color: #2c2c2b;
}
.chat-user-list li a {
  width: 100%;
  padding: 15px 10px;
  border-left: 3px solid transparent;
  color: #0b2645;
}
.chat-user-list li a:hover, .chat-user-list li a.active {
  background-color: #f2e6f9;
  border-left-color: #3f165a;
}
.chat-user-list li a h4 {
  font-size: 16px;
  font-weight: 700;
}
.chat-user-list li a h4 time {
  font-size: 11px;
  color: #2c2c2b;
}

.message-page {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.message-page .slimscroll-grid {
  z-index: 5 !important;
}
.message-page .slimscroll-bar {
  z-index: 6 !important;
}
.message-page .msg-friend-list {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 7;
}
.message-page .user-box {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  position: relative;
  min-height: 585px;
}
.message-page .msg-list-box {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.message-page .search-list-group {
  border-bottom: 1px solid #d9d9d9;
  background-color: #f2e6f9;
}
.message-page .search-list-group .crate-group .mat-stroked-button {
  padding: 0px 5px;
  min-width: 35px;
}
.message-page .user-list {
  padding: 0px 0px 0px 0px;
  margin: 0px;
  list-style: none;
}
.message-page .user-list li {
  border-bottom: 1px solid #d9d9d9;
  position: relative;
}
.message-page .user-list li:hover .action a {
  opacity: 1;
}
.message-page .user-list li .action {
  position: absolute;
  right: 2px;
  z-index: 4;
  top: -4px;
}
.message-page .user-list li .action a {
  opacity: 0;
  cursor: pointer;
}
.message-page .user-list li .action a[aria-expanded=true] {
  opacity: 1;
}
.message-page .user-list li .count {
  color: #fff;
  background-color: #3f165a;
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  right: 6px;
  z-index: 6;
}
.message-page .user-list li .img {
  width: 50px;
  margin-right: 10px;
}
.message-page .user-list li .caption {
  width: calc(100% - 60px);
}
.message-page .user-list li .text {
  font-size: 14px;
  color: #2c2c2b;
}
.message-page .user-list li a {
  width: 100%;
  padding: 15px 10px;
  border-left: 3px solid transparent;
  color: #0b2645;
}
.message-page .user-list li a:hover, .message-page .user-list li a.active {
  background-color: #f2e6f9;
  border-left-color: #3f165a;
}
.message-page .user-list li a h4 {
  font-size: 16px;
  font-weight: 700;
}
.message-page .user-list li a h4 time {
  font-size: 11px;
  color: #2c2c2b;
}
.message-page .left-scroll {
  height: 515px;
  overflow-y: auto;
}
.message-page .right-scroll {
  height: 412px;
  overflow: auto;
}
.message-page .right-scroll ng-scrollbar-y {
  display: block !important;
}
.message-page .user-info {
  padding: 12px 0px;
}
.message-page .user-info .caption h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
}
.message-page .user-info .caption time {
  font-size: 12px;
  color: #cccccc;
}
.message-page .user-info .caption .right .mat-button {
  padding: 0px 10px;
  min-width: 10px;
}
.message-page .user-info .caption .right .mat-button:hover, .message-page .user-info .caption .right .mat-button:focus {
  color: #fff;
}
.message-page .msg-list {
  padding: 10px 12px 10px 20px;
  margin: 0px;
  list-style: none;
  float: left;
  width: 100%;
}
.message-page .msg-list li {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
}
.message-page .msg-list li app-message-item {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
}
.message-page .msg-list li.mymsg {
  justify-content: flex-end;
  float: right;
}
.message-page .msg-list li.mymsg app-message-item {
  justify-content: flex-end;
  float: right;
}
.message-page .msg-list li.mymsg .msg-text {
  background-color: #f4eafa;
}
.message-page .msg-list li.mymsg .pic {
  order: 1 !important;
  padding-left: 6px;
}
.message-page .msg-list li.youmsg {
  float: left;
}
.message-page .msg-list li.youmsg app-message-item {
  float: left;
}
.message-page .msg-list li .pic {
  max-width: 100px;
  line-height: 18px;
  text-align: center;
}
.message-page .msg-list li .pic img {
  margin-bottom: 2px;
}
.message-page .msg-list li .pic small {
  font-size: 10px;
  width: 100%;
  float: left;
}
.message-page .msg-list li .msg-text {
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  color: #2c2c2b;
  max-width: calc(100% - 100px);
}
.message-page .msg-list li .msg-text time {
  font-size: 11px;
  padding-left: 8px;
}
.message-page .comment-form {
  position: relative;
}
.message-page .comment-form .uploads {
  width: 100%;
  left: 0px;
  bottom: calc(100% + 1px);
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  border-top: 1px solid #d9d9d9;
  z-index: 1;
}
.message-page .comment-form .uploads .images {
  width: 60px;
  margin-bottom: 6px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
}
.message-page .comment-form .uploads .images img {
  width: 100%;
  height: 50px;
  object-fit: cover;
  object-position: center;
}
.message-page .comment-form .uploads .images .btn {
  position: absolute;
  right: 4px;
  font-size: 12px;
  padding: 3px 6px;
}
.message-page .comment-form .attachment {
  position: relative;
  padding: 5px;
}
.message-page .comment-form .attachment .file {
  position: relative;
  color: #fff;
  background-color: #3f165a;
}
.message-page .comment-form .attachment .file input {
  position: absolute;
  width: 64px;
  left: 0px;
  top: 0px;
  height: 34px;
  opacity: 0;
  cursor: pointer;
}
.message-page .comment-form .text .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.message-page .comment-form .text .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.message-page .comment-form .text .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 0px;
  margin-top: 0px;
}
.message-page .comment-form .text textarea.mat-input-element {
  min-height: 62px;
}
.message-page .comment-form .botton {
  padding: 5px;
  position: relative;
}
.message-page .blank-msg {
  height: 582px;
}
@media (max-width: 767px) {
  .message-page .user-box {
    min-height: 400px;
    margin-bottom: 15px;
  }
  .message-page .left-scroll {
    height: 330px;
  }
}

.user-setting .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0px;
  margin: 0px;
}
.user-setting .mat-form-field-label-wrapper {
  padding-top: 0px;
}
.user-setting .mat-form-field-infix {
  padding: 15px 0px 7px 0px;
  margin: 0px;
  border-top: none;
}
.user-setting .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(-5%);
}

.theme-blue a, .theme-blue a:hover {
  color: #01579B;
}
.theme-blue .btn-link {
  color: rgba(1, 87, 155, 0.5);
}
.theme-blue .text-primary {
  color: #01579B !important;
}
.theme-blue .bg-primary {
  background-color: #01579B !important;
}
.theme-blue .spinner-size.mat-primary circle {
  stroke: #01579B;
}
.theme-blue .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-blue .mat-form-field.mat-focused .mat-form-field-label {
  color: #01579B;
}
.theme-blue .mat-fab.mat-primary, .theme-blue .mat-flat-button.mat-primary, .theme-blue .mat-mini-fab.mat-primary, .theme-blue .mat-raised-button.mat-primary, .theme-blue .mat-tab-group.mat-primary .mat-ink-bar, .theme-blue .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-blue .mat-fab.mat-primary, .theme-blue .mat-flat-button.mat-primary, .theme-blue .mat-mini-fab.mat-primary, .theme-blue .mat-raised-button.mat-primary {
  background-color: #01579B;
}
.theme-blue .btn-primary {
  border-color: #01579B;
  background-color: #01579B;
  color: #fff;
}
.theme-blue .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 87, 155, 0.25);
}
.theme-blue .btn-primary:hover {
  color: #fff;
}
.theme-blue a.mat-primary:hover, .theme-blue button.mat-primary:hover {
  color: #fff;
}
.theme-blue .form-control {
  border-color: #fff;
}
.theme-blue .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 87, 155, 0.25);
}
.theme-blue header#landing-header {
  background-color: #01579B;
  background-image: linear-gradient(#0173ce, #01579B);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
}
.theme-blue .timeline-menu .nav-link:hover {
  color: #01579B;
}
.theme-blue .timeline-menu .nav-link.active {
  background-color: rgba(1, 87, 155, 0.1);
  color: #01579B;
}
.theme-blue nav.landing-navbar .nav-link:hover, .theme-blue nav.landing-navbar .nav-link.active {
  background-color: white;
  color: #01579B;
}
.theme-blue .profile-card .card-header, .theme-blue .blog-popular ul li .image .count {
  background-color: #01579B;
}
.theme-blue .profile-card ul li a, .theme-blue footer#footer .footer-menu li a:hover, .theme-blue .blog-popular ul li .caption h4 a:hover, .theme-blue .new-post .button ul li button, .theme-blue .new-post .button ul li label, .theme-blue .timeline-post .comment-list .likes .btn b, .theme-blue .timeline-post .comment-share .btn:hover {
  color: #01579B;
}

.theme-purple a, .theme-purple a:hover {
  color: #6A1B9A;
}
.theme-purple .btn-link {
  color: rgba(106, 27, 154, 0.5);
}
.theme-purple .text-primary {
  color: #6A1B9A !important;
}
.theme-purple .bg-primary {
  background-color: #6A1B9A !important;
}
.theme-purple .spinner-size.mat-primary circle {
  stroke: #6A1B9A;
}
.theme-purple .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-purple .mat-form-field.mat-focused .mat-form-field-label {
  color: #6A1B9A;
}
.theme-purple .mat-fab.mat-primary, .theme-purple .mat-flat-button.mat-primary, .theme-purple .mat-mini-fab.mat-primary, .theme-purple .mat-raised-button.mat-primary, .theme-purple .mat-tab-group.mat-primary .mat-ink-bar, .theme-purple .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-purple .mat-fab.mat-primary, .theme-purple .mat-flat-button.mat-primary, .theme-purple .mat-mini-fab.mat-primary, .theme-purple .mat-raised-button.mat-primary {
  background-color: #6A1B9A;
}
.theme-purple .btn-primary {
  border-color: #6A1B9A;
  background-color: #6A1B9A;
  color: #fff;
}
.theme-purple .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 27, 154, 0.25);
}
.theme-purple .btn-primary:hover {
  color: #fff;
}
.theme-purple a.mat-primary:hover, .theme-purple button.mat-primary:hover {
  color: #fff;
}
.theme-purple .form-control {
  border-color: #fff;
}
.theme-purple .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 27, 154, 0.25);
}
.theme-purple header#landing-header {
  background-color: #6A1B9A;
  background-image: linear-gradient(#8823c5, #6A1B9A);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
}
.theme-purple .timeline-menu .nav-link:hover {
  color: #6A1B9A;
}
.theme-purple .timeline-menu .nav-link.active {
  background-color: rgba(106, 27, 154, 0.1);
  color: #6A1B9A;
}
.theme-purple nav.landing-navbar .nav-link:hover, .theme-purple nav.landing-navbar .nav-link.active {
  background-color: white;
  color: #6A1B9A;
}
.theme-purple .profile-card .card-header, .theme-purple .blog-popular ul li .image .count {
  background-color: #6A1B9A;
}
.theme-purple .profile-card ul li a, .theme-purple footer#footer .footer-menu li a:hover, .theme-purple .blog-popular ul li .caption h4 a:hover, .theme-purple .new-post .button ul li button, .theme-purple .new-post .button ul li label, .theme-purple .timeline-post .comment-list .likes .btn b, .theme-purple .timeline-post .comment-share .btn:hover {
  color: #6A1B9A;
}

.theme-green a, .theme-green a:hover {
  color: #1B5E20;
}
.theme-green .btn-link {
  color: rgba(27, 94, 32, 0.5);
}
.theme-green .text-primary {
  color: #1B5E20 !important;
}
.theme-green .bg-primary {
  background-color: #1B5E20 !important;
}
.theme-green .spinner-size.mat-primary circle {
  stroke: #1B5E20;
}
.theme-green .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-green .mat-form-field.mat-focused .mat-form-field-label {
  color: #1B5E20;
}
.theme-green .mat-fab.mat-primary, .theme-green .mat-flat-button.mat-primary, .theme-green .mat-mini-fab.mat-primary, .theme-green .mat-raised-button.mat-primary, .theme-green .mat-tab-group.mat-primary .mat-ink-bar, .theme-green .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-green .mat-fab.mat-primary, .theme-green .mat-flat-button.mat-primary, .theme-green .mat-mini-fab.mat-primary, .theme-green .mat-raised-button.mat-primary {
  background-color: #1B5E20;
}
.theme-green .btn-primary {
  border-color: #1B5E20;
  background-color: #1B5E20;
  color: #fff;
}
.theme-green .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 94, 32, 0.25);
}
.theme-green .btn-primary:hover {
  color: #fff;
}
.theme-green a.mat-primary:hover, .theme-green button.mat-primary:hover {
  color: #fff;
}
.theme-green .form-control {
  border-color: #fff;
}
.theme-green .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 94, 32, 0.25);
}
.theme-green header#landing-header {
  background-color: #1B5E20;
  background-image: linear-gradient(#26862d, #1B5E20);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
}
.theme-green .timeline-menu .nav-link:hover {
  color: #1B5E20;
}
.theme-green .timeline-menu .nav-link.active {
  background-color: rgba(27, 94, 32, 0.1);
  color: #1B5E20;
}
.theme-green nav.landing-navbar .nav-link:hover, .theme-green nav.landing-navbar .nav-link.active {
  background-color: #eefaef;
  color: #1B5E20;
}
.theme-green .profile-card .card-header, .theme-green .blog-popular ul li .image .count {
  background-color: #1B5E20;
}
.theme-green .profile-card ul li a, .theme-green footer#footer .footer-menu li a:hover, .theme-green .blog-popular ul li .caption h4 a:hover, .theme-green .new-post .button ul li button, .theme-green .new-post .button ul li label, .theme-green .timeline-post .comment-list .likes .btn b, .theme-green .timeline-post .comment-share .btn:hover {
  color: #1B5E20;
}

.theme-black a, .theme-black a:hover {
  color: #000;
}
.theme-black .btn-link {
  color: rgba(0, 0, 0, 0.5);
}
.theme-black .text-primary {
  color: #000 !important;
}
.theme-black .bg-primary {
  background-color: #000 !important;
}
.theme-black .spinner-size.mat-primary circle {
  stroke: #000;
}
.theme-black .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-black .mat-form-field.mat-focused .mat-form-field-label {
  color: #000;
}
.theme-black .mat-fab.mat-primary, .theme-black .mat-flat-button.mat-primary, .theme-black .mat-mini-fab.mat-primary, .theme-black .mat-raised-button.mat-primary, .theme-black .mat-tab-group.mat-primary .mat-ink-bar, .theme-black .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-black .mat-fab.mat-primary, .theme-black .mat-flat-button.mat-primary, .theme-black .mat-mini-fab.mat-primary, .theme-black .mat-raised-button.mat-primary {
  background-color: #000;
}
.theme-black .btn-primary {
  border-color: #000;
  background-color: #000;
  color: #fff;
}
.theme-black .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
.theme-black .btn-primary:hover {
  color: #fff;
}
.theme-black a.mat-primary:hover, .theme-black button.mat-primary:hover {
  color: #fff;
}
.theme-black .form-control {
  border-color: #fff;
}
.theme-black .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
.theme-black header#landing-header {
  background-color: #000;
  background-image: linear-gradient(#1a1a1a, #000);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
}
.theme-black .timeline-menu .nav-link:hover {
  color: #000;
}
.theme-black .timeline-menu .nav-link.active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}
.theme-black nav.landing-navbar .nav-link:hover, .theme-black nav.landing-navbar .nav-link.active {
  background-color: #b8b8b8;
  color: #000;
}
.theme-black .profile-card .card-header, .theme-black .blog-popular ul li .image .count {
  background-color: #000;
}
.theme-black .profile-card ul li a, .theme-black footer#footer .footer-menu li a:hover, .theme-black .blog-popular ul li .caption h4 a:hover, .theme-black .new-post .button ul li button, .theme-black .new-post .button ul li label, .theme-black .timeline-post .comment-list .likes .btn b, .theme-black .timeline-post .comment-share .btn:hover {
  color: #000;
}

.theme-orange a, .theme-orange a:hover {
  color: #BF360C;
}
.theme-orange .btn-link {
  color: rgba(191, 54, 12, 0.5);
}
.theme-orange .text-primary {
  color: #BF360C !important;
}
.theme-orange .bg-primary {
  background-color: #BF360C !important;
}
.theme-orange .spinner-size.mat-primary circle {
  stroke: #BF360C;
}
.theme-orange .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-orange .mat-form-field.mat-focused .mat-form-field-label {
  color: #BF360C;
}
.theme-orange .mat-fab.mat-primary, .theme-orange .mat-flat-button.mat-primary, .theme-orange .mat-mini-fab.mat-primary, .theme-orange .mat-raised-button.mat-primary, .theme-orange .mat-tab-group.mat-primary .mat-ink-bar, .theme-orange .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-orange .mat-fab.mat-primary, .theme-orange .mat-flat-button.mat-primary, .theme-orange .mat-mini-fab.mat-primary, .theme-orange .mat-raised-button.mat-primary {
  background-color: #BF360C;
}
.theme-orange .btn-primary {
  border-color: #BF360C;
  background-color: #BF360C;
  color: #fff;
}
.theme-orange .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 12, 0.25);
}
.theme-orange .btn-primary:hover {
  color: #fff;
}
.theme-orange a.mat-primary:hover, .theme-orange button.mat-primary:hover {
  color: #fff;
}
.theme-orange .form-control {
  border-color: #fff;
}
.theme-orange .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 12, 0.25);
}
.theme-orange header#landing-header {
  background-color: #BF360C;
  background-image: linear-gradient(#ef440f, #BF360C);
  box-shadow: 0px 0px 8px rgba(191, 54, 12, 0.35);
}
.theme-orange .timeline-menu .nav-link:hover {
  color: #BF360C;
}
.theme-orange .timeline-menu .nav-link.active {
  background-color: rgba(191, 54, 12, 0.1);
  color: #BF360C;
}
.theme-orange nav.landing-navbar .nav-link:hover, .theme-orange nav.landing-navbar .nav-link.active {
  background-color: white;
  color: #BF360C;
}
.theme-orange .profile-card .card-header, .theme-orange .blog-popular ul li .image .count {
  background-color: #BF360C;
}
.theme-orange .profile-card ul li a, .theme-orange footer#footer .footer-menu li a:hover, .theme-orange .blog-popular ul li .caption h4 a:hover, .theme-orange .new-post .button ul li button, .theme-orange .new-post .button ul li label, .theme-orange .timeline-post .comment-list .likes .btn b, .theme-orange .timeline-post .comment-share .btn:hover {
  color: #BF360C;
}

.theme-teal a, .theme-teal a:hover {
  color: #004D40;
}
.theme-teal .btn-link {
  color: rgba(0, 77, 64, 0.5);
}
.theme-teal .text-primary {
  color: #004D40 !important;
}
.theme-teal .bg-primary {
  background-color: #004D40 !important;
}
.theme-teal .spinner-size.mat-primary circle {
  stroke: #004D40;
}
.theme-teal .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-teal .mat-form-field.mat-focused .mat-form-field-label {
  color: #004D40;
}
.theme-teal .mat-fab.mat-primary, .theme-teal .mat-flat-button.mat-primary, .theme-teal .mat-mini-fab.mat-primary, .theme-teal .mat-raised-button.mat-primary, .theme-teal .mat-tab-group.mat-primary .mat-ink-bar, .theme-teal .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-teal .mat-fab.mat-primary, .theme-teal .mat-flat-button.mat-primary, .theme-teal .mat-mini-fab.mat-primary, .theme-teal .mat-raised-button.mat-primary {
  background-color: #004D40;
}
.theme-teal .btn-primary {
  border-color: #004D40;
  background-color: #004D40;
  color: #fff;
}
.theme-teal .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 77, 64, 0.25);
}
.theme-teal .btn-primary:hover {
  color: #fff;
}
.theme-teal a.mat-primary:hover, .theme-teal button.mat-primary:hover {
  color: #fff;
}
.theme-teal .form-control {
  border-color: #fff;
}
.theme-teal .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 77, 64, 0.25);
}
.theme-teal header#landing-header {
  background-color: #004D40;
  background-image: linear-gradient(#00806a, #004D40);
  box-shadow: 0px 0px 8px rgba(0, 77, 64, 0.35);
}
.theme-teal .timeline-menu .nav-link:hover {
  color: #004D40;
}
.theme-teal .timeline-menu .nav-link.active {
  background-color: rgba(0, 77, 64, 0.1);
  color: #004D40;
}
.theme-teal nav.landing-navbar .nav-link:hover, .theme-teal nav.landing-navbar .nav-link.active {
  background-color: #bdfff4;
  color: #004D40;
}
.theme-teal .profile-card .card-header, .theme-teal .blog-popular ul li .image .count {
  background-color: #004D40;
}
.theme-teal .profile-card ul li a, .theme-teal footer#footer .footer-menu li a:hover, .theme-teal .blog-popular ul li .caption h4 a:hover, .theme-teal .new-post .button ul li button, .theme-teal .new-post .button ul li label, .theme-teal .timeline-post .comment-list .likes .btn b, .theme-teal .timeline-post .comment-share .btn:hover {
  color: #004D40;
}

.theme-cyan a, .theme-cyan a:hover {
  color: #006064;
}
.theme-cyan .btn-link {
  color: rgba(0, 96, 100, 0.5);
}
.theme-cyan .text-primary {
  color: #006064 !important;
}
.theme-cyan .bg-primary {
  background-color: #006064 !important;
}
.theme-cyan .spinner-size.mat-primary circle {
  stroke: #006064;
}
.theme-cyan .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-cyan .mat-form-field.mat-focused .mat-form-field-label {
  color: #006064;
}
.theme-cyan .mat-fab.mat-primary, .theme-cyan .mat-flat-button.mat-primary, .theme-cyan .mat-mini-fab.mat-primary, .theme-cyan .mat-raised-button.mat-primary, .theme-cyan .mat-tab-group.mat-primary .mat-ink-bar, .theme-cyan .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-cyan .mat-fab.mat-primary, .theme-cyan .mat-flat-button.mat-primary, .theme-cyan .mat-mini-fab.mat-primary, .theme-cyan .mat-raised-button.mat-primary {
  background-color: #006064;
}
.theme-cyan .btn-primary {
  border-color: #006064;
  background-color: #006064;
  color: #fff;
}
.theme-cyan .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 96, 100, 0.25);
}
.theme-cyan .btn-primary:hover {
  color: #fff;
}
.theme-cyan a.mat-primary:hover, .theme-cyan button.mat-primary:hover {
  color: #fff;
}
.theme-cyan .form-control {
  border-color: #fff;
}
.theme-cyan .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 96, 100, 0.25);
}
.theme-cyan header#landing-header {
  background-color: #006064;
  background-image: linear-gradient(#009197, #006064);
  box-shadow: 0px 0px 8px rgba(0, 96, 100, 0.35);
}
.theme-cyan .timeline-menu .nav-link:hover {
  color: #006064;
}
.theme-cyan .timeline-menu .nav-link.active {
  background-color: rgba(0, 96, 100, 0.1);
  color: #006064;
}
.theme-cyan nav.landing-navbar .nav-link:hover, .theme-cyan nav.landing-navbar .nav-link.active {
  background-color: #d4fdff;
  color: #006064;
}
.theme-cyan .profile-card .card-header, .theme-cyan .blog-popular ul li .image .count {
  background-color: #006064;
}
.theme-cyan .profile-card ul li a, .theme-cyan footer#footer .footer-menu li a:hover, .theme-cyan .blog-popular ul li .caption h4 a:hover, .theme-cyan .new-post .button ul li button, .theme-cyan .new-post .button ul li label, .theme-cyan .timeline-post .comment-list .likes .btn b, .theme-cyan .timeline-post .comment-share .btn:hover {
  color: #006064;
}

.theme-gray a, .theme-gray a:hover {
  color: #455A64;
}
.theme-gray .btn-link {
  color: rgba(69, 90, 100, 0.5);
}
.theme-gray .text-primary {
  color: #455A64 !important;
}
.theme-gray .bg-primary {
  background-color: #455A64 !important;
}
.theme-gray .spinner-size.mat-primary circle {
  stroke: #455A64;
}
.theme-gray .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-gray .mat-form-field.mat-focused .mat-form-field-label {
  color: #455A64;
}
.theme-gray .mat-fab.mat-primary, .theme-gray .mat-flat-button.mat-primary, .theme-gray .mat-mini-fab.mat-primary, .theme-gray .mat-raised-button.mat-primary, .theme-gray .mat-tab-group.mat-primary .mat-ink-bar, .theme-gray .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-gray .mat-fab.mat-primary, .theme-gray .mat-flat-button.mat-primary, .theme-gray .mat-mini-fab.mat-primary, .theme-gray .mat-raised-button.mat-primary {
  background-color: #455A64;
}
.theme-gray .btn-primary {
  border-color: #455A64;
  background-color: #455A64;
  color: #fff;
}
.theme-gray .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 90, 100, 0.25);
}
.theme-gray .btn-primary:hover {
  color: #fff;
}
.theme-gray a.mat-primary:hover, .theme-gray button.mat-primary:hover {
  color: #fff;
}
.theme-gray .form-control {
  border-color: #fff;
}
.theme-gray .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 90, 100, 0.25);
}
.theme-gray header#landing-header {
  background-color: #455A64;
  background-image: linear-gradient(#5a7582, #455A64);
  box-shadow: 0px 0px 8px rgba(69, 90, 100, 0.35);
}
.theme-gray .timeline-menu .nav-link:hover {
  color: #455A64;
}
.theme-gray .timeline-menu .nav-link.active {
  background-color: rgba(69, 90, 100, 0.1);
  color: #455A64;
}
.theme-gray nav.landing-navbar .nav-link:hover, .theme-gray nav.landing-navbar .nav-link.active {
  background-color: white;
  color: #455A64;
}
.theme-gray .profile-card .card-header, .theme-gray .blog-popular ul li .image .count {
  background-color: #455A64;
}
.theme-gray .profile-card ul li a, .theme-gray footer#footer .footer-menu li a:hover, .theme-gray .blog-popular ul li .caption h4 a:hover, .theme-gray .new-post .button ul li button, .theme-gray .new-post .button ul li label, .theme-gray .timeline-post .comment-list .likes .btn b, .theme-gray .timeline-post .comment-share .btn:hover {
  color: #455A64;
}

.theme-red a, .theme-red a:hover {
  color: #B71C1C;
}
.theme-red .btn-link {
  color: rgba(183, 28, 28, 0.5);
}
.theme-red .text-primary {
  color: #B71C1C !important;
}
.theme-red .bg-primary {
  background-color: #B71C1C !important;
}
.theme-red .spinner-size.mat-primary circle {
  stroke: #B71C1C;
}
.theme-red .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .theme-red .mat-form-field.mat-focused .mat-form-field-label {
  color: #B71C1C;
}
.theme-red .mat-fab.mat-primary, .theme-red .mat-flat-button.mat-primary, .theme-red .mat-mini-fab.mat-primary, .theme-red .mat-raised-button.mat-primary, .theme-red .mat-tab-group.mat-primary .mat-ink-bar, .theme-red .mat-tab-nav-bar.mat-primary .mat-ink-bar, .theme-red .mat-fab.mat-primary, .theme-red .mat-flat-button.mat-primary, .theme-red .mat-mini-fab.mat-primary, .theme-red .mat-raised-button.mat-primary {
  background-color: #B71C1C;
}
.theme-red .btn-primary {
  border-color: #B71C1C;
  background-color: #B71C1C;
  color: #fff;
}
.theme-red .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 28, 28, 0.25);
}
.theme-red .btn-primary:hover {
  color: #fff;
}
.theme-red a.mat-primary:hover, .theme-red button.mat-primary:hover {
  color: #fff;
}
.theme-red .form-control {
  border-color: #fff;
}
.theme-red .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 28, 28, 0.25);
}
.theme-red header#landing-header {
  background-color: #B71C1C;
  background-image: linear-gradient(#de2828, #B71C1C);
  box-shadow: 0px 0px 8px rgba(183, 28, 28, 0.35);
}
.theme-red .timeline-menu .nav-link:hover {
  color: #B71C1C;
}
.theme-red .timeline-menu .nav-link.active {
  background-color: rgba(183, 28, 28, 0.1);
  color: #B71C1C;
}
.theme-red nav.landing-navbar .nav-link:hover, .theme-red nav.landing-navbar .nav-link.active {
  background-color: white;
  color: #B71C1C;
}
.theme-red .profile-card .card-header, .theme-red .blog-popular ul li .image .count {
  background-color: #B71C1C;
}
.theme-red .profile-card ul li a, .theme-red footer#footer .footer-menu li a:hover, .theme-red .blog-popular ul li .caption h4 a:hover, .theme-red .new-post .button ul li button, .theme-red .new-post .button ul li label, .theme-red .timeline-post .comment-list .likes .btn b, .theme-red .timeline-post .comment-share .btn:hover {
  color: #B71C1C;
}

/* Sass Arrays and Mixins */
.balloons {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 99;
  pointer-events: none;
}

.balloon {
  height: 130px;
  width: 104px;
  border-radius: 80% 80% 80% 80%;
  background-color: red;
  position: relative;
  margin: 0 5px;
}
.balloon:before {
  content: "";
  color: rgba(182, 15, 97, 0.9);
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid red;
}
.balloon .balloon-ref {
  position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 80%;
  border-radius: 90%;
  background: white;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.41015625) 0%, rgba(148, 214, 233, 0) 100%);
}
.balloon span.top-c {
  position: absolute;
  top: 0px;
  left: 20px;
  width: 15px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotate(48deg);
}
.balloon span.left-c {
  position: absolute;
  top: 36px;
  left: 6px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotate(48deg);
}

.balloon0 {
  background-color: #0b2645;
  left: 1%;
  animation: flyingBalloon 20s -67s linear infinite;
  -webkit-animation: flyingBalloon 20s -67s linear infinite;
  -moz-animation: flyingBalloon 20s -67s linear infinite;
  -ms-animation: flyingBalloon 20s -67s linear infinite;
  -o-animation: flyingBalloon 20s -67s linear infinite;
}
.balloon0:before {
  border-bottom: 10px solid #0b2645;
}

.balloon1 {
  background-color: #0b2645;
  left: 63%;
  animation: flyingBalloon 56s -72s linear infinite;
  -webkit-animation: flyingBalloon 56s -72s linear infinite;
  -moz-animation: flyingBalloon 56s -72s linear infinite;
  -ms-animation: flyingBalloon 56s -72s linear infinite;
  -o-animation: flyingBalloon 56s -72s linear infinite;
}
.balloon1:before {
  border-bottom: 10px solid #0b2645;
}

.balloon2 {
  background-color: #f63869;
  left: 46%;
  animation: flyingBalloon 27s -57s linear infinite;
  -webkit-animation: flyingBalloon 27s -57s linear infinite;
  -moz-animation: flyingBalloon 27s -57s linear infinite;
  -ms-animation: flyingBalloon 27s -57s linear infinite;
  -o-animation: flyingBalloon 27s -57s linear infinite;
}
.balloon2:before {
  border-bottom: 10px solid #f63869;
}

.balloon3 {
  background-color: #f63869;
  left: 64%;
  animation: flyingBalloon 33s -88s linear infinite;
  -webkit-animation: flyingBalloon 33s -88s linear infinite;
  -moz-animation: flyingBalloon 33s -88s linear infinite;
  -ms-animation: flyingBalloon 33s -88s linear infinite;
  -o-animation: flyingBalloon 33s -88s linear infinite;
}
.balloon3:before {
  border-bottom: 10px solid #f63869;
}

.balloon4 {
  background-color: #2678d2;
  left: 55%;
  animation: flyingBalloon 55s -89s linear infinite;
  -webkit-animation: flyingBalloon 55s -89s linear infinite;
  -moz-animation: flyingBalloon 55s -89s linear infinite;
  -ms-animation: flyingBalloon 55s -89s linear infinite;
  -o-animation: flyingBalloon 55s -89s linear infinite;
}
.balloon4:before {
  border-bottom: 10px solid #2678d2;
}

.balloon5 {
  background-color: #0b2645;
  left: 67%;
  animation: flyingBalloon 42s -62s linear infinite;
  -webkit-animation: flyingBalloon 42s -62s linear infinite;
  -moz-animation: flyingBalloon 42s -62s linear infinite;
  -ms-animation: flyingBalloon 42s -62s linear infinite;
  -o-animation: flyingBalloon 42s -62s linear infinite;
}
.balloon5:before {
  border-bottom: 10px solid #0b2645;
}

.balloon6 {
  background-color: #2678d2;
  left: 35%;
  animation: flyingBalloon 49s -89s linear infinite;
  -webkit-animation: flyingBalloon 49s -89s linear infinite;
  -moz-animation: flyingBalloon 49s -89s linear infinite;
  -ms-animation: flyingBalloon 49s -89s linear infinite;
  -o-animation: flyingBalloon 49s -89s linear infinite;
}
.balloon6:before {
  border-bottom: 10px solid #2678d2;
}

.balloon7 {
  background-color: #6A1B9A;
  left: 54%;
  animation: flyingBalloon 30s -109s linear infinite;
  -webkit-animation: flyingBalloon 30s -109s linear infinite;
  -moz-animation: flyingBalloon 30s -109s linear infinite;
  -ms-animation: flyingBalloon 30s -109s linear infinite;
  -o-animation: flyingBalloon 30s -109s linear infinite;
}
.balloon7:before {
  border-bottom: 10px solid #6A1B9A;
}

.balloon8 {
  background-color: #60c31f;
  left: 20%;
  animation: flyingBalloon 10s -120s linear infinite;
  -webkit-animation: flyingBalloon 10s -120s linear infinite;
  -moz-animation: flyingBalloon 10s -120s linear infinite;
  -ms-animation: flyingBalloon 10s -120s linear infinite;
  -o-animation: flyingBalloon 10s -120s linear infinite;
}
.balloon8:before {
  border-bottom: 10px solid #60c31f;
}

.balloon9 {
  background-color: #01579B;
  left: 25%;
  animation: flyingBalloon 54s -75s linear infinite;
  -webkit-animation: flyingBalloon 54s -75s linear infinite;
  -moz-animation: flyingBalloon 54s -75s linear infinite;
  -ms-animation: flyingBalloon 54s -75s linear infinite;
  -o-animation: flyingBalloon 54s -75s linear infinite;
}
.balloon9:before {
  border-bottom: 10px solid #01579B;
}

.balloon10 {
  background-color: #3f165a;
  left: 8%;
  animation: flyingBalloon 21s -119s linear infinite;
  -webkit-animation: flyingBalloon 21s -119s linear infinite;
  -moz-animation: flyingBalloon 21s -119s linear infinite;
  -ms-animation: flyingBalloon 21s -119s linear infinite;
  -o-animation: flyingBalloon 21s -119s linear infinite;
}
.balloon10:before {
  border-bottom: 10px solid #3f165a;
}

.balloon11 {
  background-color: #db1c24;
  left: 27%;
  animation: flyingBalloon 24s -74s linear infinite;
  -webkit-animation: flyingBalloon 24s -74s linear infinite;
  -moz-animation: flyingBalloon 24s -74s linear infinite;
  -ms-animation: flyingBalloon 24s -74s linear infinite;
  -o-animation: flyingBalloon 24s -74s linear infinite;
}
.balloon11:before {
  border-bottom: 10px solid #db1c24;
}

.balloon12 {
  background-color: #f63869;
  left: 5%;
  animation: flyingBalloon 11s -101s linear infinite;
  -webkit-animation: flyingBalloon 11s -101s linear infinite;
  -moz-animation: flyingBalloon 11s -101s linear infinite;
  -ms-animation: flyingBalloon 11s -101s linear infinite;
  -o-animation: flyingBalloon 11s -101s linear infinite;
}
.balloon12:before {
  border-bottom: 10px solid #f63869;
}

.balloon13 {
  background-color: #0b2645;
  left: 46%;
  animation: flyingBalloon 44s -83s linear infinite;
  -webkit-animation: flyingBalloon 44s -83s linear infinite;
  -moz-animation: flyingBalloon 44s -83s linear infinite;
  -ms-animation: flyingBalloon 44s -83s linear infinite;
  -o-animation: flyingBalloon 44s -83s linear infinite;
}
.balloon13:before {
  border-bottom: 10px solid #0b2645;
}

.balloon14 {
  background-color: #01579B;
  left: 43%;
  animation: flyingBalloon 20s -76s linear infinite;
  -webkit-animation: flyingBalloon 20s -76s linear infinite;
  -moz-animation: flyingBalloon 20s -76s linear infinite;
  -ms-animation: flyingBalloon 20s -76s linear infinite;
  -o-animation: flyingBalloon 20s -76s linear infinite;
}
.balloon14:before {
  border-bottom: 10px solid #01579B;
}

.balloon15 {
  background-color: #6A1B9A;
  left: 20%;
  animation: flyingBalloon 28s -88s linear infinite;
  -webkit-animation: flyingBalloon 28s -88s linear infinite;
  -moz-animation: flyingBalloon 28s -88s linear infinite;
  -ms-animation: flyingBalloon 28s -88s linear infinite;
  -o-animation: flyingBalloon 28s -88s linear infinite;
}
.balloon15:before {
  border-bottom: 10px solid #6A1B9A;
}

.balloon16 {
  background-color: #f63869;
  left: 5%;
  animation: flyingBalloon 45s -55s linear infinite;
  -webkit-animation: flyingBalloon 45s -55s linear infinite;
  -moz-animation: flyingBalloon 45s -55s linear infinite;
  -ms-animation: flyingBalloon 45s -55s linear infinite;
  -o-animation: flyingBalloon 45s -55s linear infinite;
}
.balloon16:before {
  border-bottom: 10px solid #f63869;
}

.balloon17 {
  background-color: #f63869;
  left: 46%;
  animation: flyingBalloon 11s -66s linear infinite;
  -webkit-animation: flyingBalloon 11s -66s linear infinite;
  -moz-animation: flyingBalloon 11s -66s linear infinite;
  -ms-animation: flyingBalloon 11s -66s linear infinite;
  -o-animation: flyingBalloon 11s -66s linear infinite;
}
.balloon17:before {
  border-bottom: 10px solid #f63869;
}

.balloon18 {
  background-color: #000;
  left: 13%;
  animation: flyingBalloon 31s -88s linear infinite;
  -webkit-animation: flyingBalloon 31s -88s linear infinite;
  -moz-animation: flyingBalloon 31s -88s linear infinite;
  -ms-animation: flyingBalloon 31s -88s linear infinite;
  -o-animation: flyingBalloon 31s -88s linear infinite;
}
.balloon18:before {
  border-bottom: 10px solid #000;
}

.balloon19 {
  background-color: #01579B;
  left: 16%;
  animation: flyingBalloon 38s -95s linear infinite;
  -webkit-animation: flyingBalloon 38s -95s linear infinite;
  -moz-animation: flyingBalloon 38s -95s linear infinite;
  -ms-animation: flyingBalloon 38s -95s linear infinite;
  -o-animation: flyingBalloon 38s -95s linear infinite;
}
.balloon19:before {
  border-bottom: 10px solid #01579B;
}

.balloon20 {
  background-color: #01579B;
  left: 7%;
  animation: flyingBalloon 12s -106s linear infinite;
  -webkit-animation: flyingBalloon 12s -106s linear infinite;
  -moz-animation: flyingBalloon 12s -106s linear infinite;
  -ms-animation: flyingBalloon 12s -106s linear infinite;
  -o-animation: flyingBalloon 12s -106s linear infinite;
}
.balloon20:before {
  border-bottom: 10px solid #01579B;
}

.balloon21 {
  background-color: #3f165a;
  left: 28%;
  animation: flyingBalloon 16s -76s linear infinite;
  -webkit-animation: flyingBalloon 16s -76s linear infinite;
  -moz-animation: flyingBalloon 16s -76s linear infinite;
  -ms-animation: flyingBalloon 16s -76s linear infinite;
  -o-animation: flyingBalloon 16s -76s linear infinite;
}
.balloon21:before {
  border-bottom: 10px solid #3f165a;
}

.balloon22 {
  background-color: #6A1B9A;
  left: 52%;
  animation: flyingBalloon 28s -86s linear infinite;
  -webkit-animation: flyingBalloon 28s -86s linear infinite;
  -moz-animation: flyingBalloon 28s -86s linear infinite;
  -ms-animation: flyingBalloon 28s -86s linear infinite;
  -o-animation: flyingBalloon 28s -86s linear infinite;
}
.balloon22:before {
  border-bottom: 10px solid #6A1B9A;
}

.balloon23 {
  background-color: #000;
  left: 46%;
  animation: flyingBalloon 29s -119s linear infinite;
  -webkit-animation: flyingBalloon 29s -119s linear infinite;
  -moz-animation: flyingBalloon 29s -119s linear infinite;
  -ms-animation: flyingBalloon 29s -119s linear infinite;
  -o-animation: flyingBalloon 29s -119s linear infinite;
}
.balloon23:before {
  border-bottom: 10px solid #000;
}

.balloon24 {
  background-color: #3f165a;
  left: 53%;
  animation: flyingBalloon 15s -51s linear infinite;
  -webkit-animation: flyingBalloon 15s -51s linear infinite;
  -moz-animation: flyingBalloon 15s -51s linear infinite;
  -ms-animation: flyingBalloon 15s -51s linear infinite;
  -o-animation: flyingBalloon 15s -51s linear infinite;
}
.balloon24:before {
  border-bottom: 10px solid #3f165a;
}

.balloon25 {
  background-color: #01579B;
  left: 30%;
  animation: flyingBalloon 53s -52s linear infinite;
  -webkit-animation: flyingBalloon 53s -52s linear infinite;
  -moz-animation: flyingBalloon 53s -52s linear infinite;
  -ms-animation: flyingBalloon 53s -52s linear infinite;
  -o-animation: flyingBalloon 53s -52s linear infinite;
}
.balloon25:before {
  border-bottom: 10px solid #01579B;
}

.balloon26 {
  background-color: #60c31f;
  left: 47%;
  animation: flyingBalloon 26s -98s linear infinite;
  -webkit-animation: flyingBalloon 26s -98s linear infinite;
  -moz-animation: flyingBalloon 26s -98s linear infinite;
  -ms-animation: flyingBalloon 26s -98s linear infinite;
  -o-animation: flyingBalloon 26s -98s linear infinite;
}
.balloon26:before {
  border-bottom: 10px solid #60c31f;
}

.balloon27 {
  background-color: #000;
  left: 69%;
  animation: flyingBalloon 11s -112s linear infinite;
  -webkit-animation: flyingBalloon 11s -112s linear infinite;
  -moz-animation: flyingBalloon 11s -112s linear infinite;
  -ms-animation: flyingBalloon 11s -112s linear infinite;
  -o-animation: flyingBalloon 11s -112s linear infinite;
}
.balloon27:before {
  border-bottom: 10px solid #000;
}

.balloon28 {
  background-color: #2678d2;
  left: 12%;
  animation: flyingBalloon 44s -80s linear infinite;
  -webkit-animation: flyingBalloon 44s -80s linear infinite;
  -moz-animation: flyingBalloon 44s -80s linear infinite;
  -ms-animation: flyingBalloon 44s -80s linear infinite;
  -o-animation: flyingBalloon 44s -80s linear infinite;
}
.balloon28:before {
  border-bottom: 10px solid #2678d2;
}

.balloon29 {
  background-color: #60c31f;
  left: 20%;
  animation: flyingBalloon 30s -102s linear infinite;
  -webkit-animation: flyingBalloon 30s -102s linear infinite;
  -moz-animation: flyingBalloon 30s -102s linear infinite;
  -ms-animation: flyingBalloon 30s -102s linear infinite;
  -o-animation: flyingBalloon 30s -102s linear infinite;
}
.balloon29:before {
  border-bottom: 10px solid #60c31f;
}

.balloon30 {
  background-color: #000;
  left: 27%;
  animation: flyingBalloon 40s -100s linear infinite;
  -webkit-animation: flyingBalloon 40s -100s linear infinite;
  -moz-animation: flyingBalloon 40s -100s linear infinite;
  -ms-animation: flyingBalloon 40s -100s linear infinite;
  -o-animation: flyingBalloon 40s -100s linear infinite;
}
.balloon30:before {
  border-bottom: 10px solid #000;
}

.balloon31 {
  background-color: #60c31f;
  left: 48%;
  animation: flyingBalloon 12s -55s linear infinite;
  -webkit-animation: flyingBalloon 12s -55s linear infinite;
  -moz-animation: flyingBalloon 12s -55s linear infinite;
  -ms-animation: flyingBalloon 12s -55s linear infinite;
  -o-animation: flyingBalloon 12s -55s linear infinite;
}
.balloon31:before {
  border-bottom: 10px solid #60c31f;
}

.balloon32 {
  background-color: #3f165a;
  left: 32%;
  animation: flyingBalloon 31s -96s linear infinite;
  -webkit-animation: flyingBalloon 31s -96s linear infinite;
  -moz-animation: flyingBalloon 31s -96s linear infinite;
  -ms-animation: flyingBalloon 31s -96s linear infinite;
  -o-animation: flyingBalloon 31s -96s linear infinite;
}
.balloon32:before {
  border-bottom: 10px solid #3f165a;
}

.balloon33 {
  background-color: #f63869;
  left: 30%;
  animation: flyingBalloon 46s -106s linear infinite;
  -webkit-animation: flyingBalloon 46s -106s linear infinite;
  -moz-animation: flyingBalloon 46s -106s linear infinite;
  -ms-animation: flyingBalloon 46s -106s linear infinite;
  -o-animation: flyingBalloon 46s -106s linear infinite;
}
.balloon33:before {
  border-bottom: 10px solid #f63869;
}

.balloon34 {
  background-color: #db1c24;
  left: 46%;
  animation: flyingBalloon 51s -53s linear infinite;
  -webkit-animation: flyingBalloon 51s -53s linear infinite;
  -moz-animation: flyingBalloon 51s -53s linear infinite;
  -ms-animation: flyingBalloon 51s -53s linear infinite;
  -o-animation: flyingBalloon 51s -53s linear infinite;
}
.balloon34:before {
  border-bottom: 10px solid #db1c24;
}

.balloon35 {
  background-color: #6A1B9A;
  left: 24%;
  animation: flyingBalloon 25s -67s linear infinite;
  -webkit-animation: flyingBalloon 25s -67s linear infinite;
  -moz-animation: flyingBalloon 25s -67s linear infinite;
  -ms-animation: flyingBalloon 25s -67s linear infinite;
  -o-animation: flyingBalloon 25s -67s linear infinite;
}
.balloon35:before {
  border-bottom: 10px solid #6A1B9A;
}

.balloon36 {
  background-color: #01579B;
  left: 1%;
  animation: flyingBalloon 40s -111s linear infinite;
  -webkit-animation: flyingBalloon 40s -111s linear infinite;
  -moz-animation: flyingBalloon 40s -111s linear infinite;
  -ms-animation: flyingBalloon 40s -111s linear infinite;
  -o-animation: flyingBalloon 40s -111s linear infinite;
}
.balloon36:before {
  border-bottom: 10px solid #01579B;
}

.balloon37 {
  background-color: #60c31f;
  left: 61%;
  animation: flyingBalloon 57s -67s linear infinite;
  -webkit-animation: flyingBalloon 57s -67s linear infinite;
  -moz-animation: flyingBalloon 57s -67s linear infinite;
  -ms-animation: flyingBalloon 57s -67s linear infinite;
  -o-animation: flyingBalloon 57s -67s linear infinite;
}
.balloon37:before {
  border-bottom: 10px solid #60c31f;
}

.balloon38 {
  background-color: #0b2645;
  left: 57%;
  animation: flyingBalloon 33s -94s linear infinite;
  -webkit-animation: flyingBalloon 33s -94s linear infinite;
  -moz-animation: flyingBalloon 33s -94s linear infinite;
  -ms-animation: flyingBalloon 33s -94s linear infinite;
  -o-animation: flyingBalloon 33s -94s linear infinite;
}
.balloon38:before {
  border-bottom: 10px solid #0b2645;
}

.balloon39 {
  background-color: #db1c24;
  left: 62%;
  animation: flyingBalloon 17s -57s linear infinite;
  -webkit-animation: flyingBalloon 17s -57s linear infinite;
  -moz-animation: flyingBalloon 17s -57s linear infinite;
  -ms-animation: flyingBalloon 17s -57s linear infinite;
  -o-animation: flyingBalloon 17s -57s linear infinite;
}
.balloon39:before {
  border-bottom: 10px solid #db1c24;
}

.balloon40 {
  background-color: #3f165a;
  left: 50%;
  animation: flyingBalloon 14s -58s linear infinite;
  -webkit-animation: flyingBalloon 14s -58s linear infinite;
  -moz-animation: flyingBalloon 14s -58s linear infinite;
  -ms-animation: flyingBalloon 14s -58s linear infinite;
  -o-animation: flyingBalloon 14s -58s linear infinite;
}
.balloon40:before {
  border-bottom: 10px solid #3f165a;
}

@keyframes flyingBalloon {
  from {
    bottom: -20%;
  }
  to {
    bottom: 110%;
  }
}
.albums-list-view .timeline-post .comment-list .likes .btn-share {
  display: none;
}

.privacy-scroll.going-user .mat-checkbox-inner-container {
  display: none;
}

@media (max-width: 991px) {
  .user-banner {
    min-height: 10px !important;
  }
  .user-banner .image {
    height: auto;
  }
}
@media (max-width: 767px) {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  [class*=col] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .container {
    padding-left: 5px;
    padding-right: 5px;
  }

  app-about .user-about {
    margin: 15px 0px;
  }

  .page-card {
    margin-bottom: 15px !important;
  }
  .page-card .card-header {
    padding: 0.55rem 10px;
  }
  .page-card .card-header .btn-link span {
    display: none;
  }
  .page-card .card-header .input-group {
    margin-right: 0px !important;
    width: 100%;
    max-width: 100%;
    margin: 6px 0px;
  }
  .page-card .card-body {
    padding: 1rem 10px;
  }
  .page-card .card-body > .py-3 {
    padding: 0px !important;
  }

  .custom-dialog .header {
    padding: 6px 10px;
  }
  .custom-dialog .header h3 {
    font-size: 16px;
  }

  .mat-dialog-content {
    padding: 10px !important;
    max-height: calc(100vh - 115px) !important;
    min-height: calc(100vh - 115px) !important;
  }

  .mat-dialog-actions {
    padding: 8px 10px !important;
  }

  .cdk-overlay-pane {
    max-width: 100vw !important;
    width: 100% !important;
  }

  .inner-page {
    padding: 15px 0px;
  }

  .custom-card.setting-menu {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .group-banner .actions {
    padding-bottom: 8px;
  }
  .group-banner .actions button, .group-banner .actions a {
    margin-bottom: 6px;
  }
  .group-banner .actions .col {
    flex-basis: inherit;
    flex-grow: inherit;
    max-width: none;
    width: auto;
  }
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  user-select: none;
}

.support-status .mat-form-field-wrapper {
  padding-bottom: 2px;
}

@media (max-width: 574px) {
  app-dashboard-layout .dashboard-page app-user-profile, app-dashboard-layout .dashboard-page app-explore-menu, app-dashboard-layout .dashboard-page app-trends-for-you {
    display: none;
  }

  .mat-select-panel {
    min-width: calc(100% - 15px) !important;
    margin-left: 48px;
  }
}
.example-tooltip-red {
  background-color: #fff;
  margin-top: 0px !important;
  color: #333 !important;
  font-weight: bold !important;
}

webcam video {
  width: 100%;
}

app-user-timeline app-advertisement {
  margin-top: 40px;
  float: left;
  width: 100%;
}

.people-you-know .list-group-item {
  margin-bottom: 10px;
  padding: 0px;
  border: none;
}
.people-you-know .list-group-item .caption {
  padding: 10px;
  border: 1px solid #ddd;
  border-top: none;
}
.people-you-know .list-group-item .caption h6 a {
  font-size: 18px;
  color: inherit;
  line-height: 30px;
}
.people-you-know .list-group-item .caption h6 a:hover {
  color: #3f165a;
}
.people-you-know .owl-theme .owl-nav .owl-prev {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
}
.people-you-know .owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
}

.view-box {
  margin-bottom: 30px;
}
.view-box .caption .content {
  padding: 10px 15px 15px;
}
.view-box .caption .content time {
  color: #d9d9d9;
}
.view-box .caption .content .text {
  font-size: 14px;
}
.view-box .caption .count-group {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 15px;
  font-size: 14px;
}
.view-box .caption .count-group b, .view-box .caption .count-group i {
  color: #3f165a;
}