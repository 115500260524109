/* You can add global styles to this file, and also import other style files */

@import "sass/variables";
@import "sass/mixins";
@import "sass/utilities";

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~ladda/dist/ladda.min.css';
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "~@ctrl/ngx-emoji-mart/picker";
@import "~bootstrap/scss/bootstrap";

//@import '~@angular/material/theming';

//@import "sass/emoji";
@import "sass/custom";
@import "~ngx-lightbox/lightbox.css";


body {font-family: 'Muli', sans-serif; line-height: 26px;} 

.text-light {color: #666 !important;}

.all-content {@include full-width(); min-height: calc(77vh - 11px); background-color: #e9ebee;}
.main-wrap {@include full-width(); min-height: calc(82vh - 16px); margin-top: 77px; background-color: #e9ebee;
	@media(max-width: 1199px) {margin-top: 68px;}
}
.inner-page {@include full-width(); padding: 30px 0px;}

body.embaded-post {
	app-header, app-footer, app-landing-navbar {display: none;}
	.main-wrap {margin-top: 0px !important;}
	.timeline-post {margin-bottom: 0px;}
}

read-more {white-space: break-spaces;}

header#landing-header {@include full-width(); background-color: $primary; background-image: linear-gradient(lighten($primary, 10%), $primary); box-shadow: 0px 0px 8px rgba($black, 0.35); padding: 6px 0px;
	.logo {padding: 6px 0px; float: left; width: 100%;}
	form.login {color: $white;
		label {width: 100%; font-size: 12px; font-weight: bold; margin-bottom: 2px;}
		.form-group {margin-bottom: 20px; padding-right: 15px; position: relative;}
		.btn {text-transform: uppercase; font-size: 12px; font-weight: bold; padding: 9px 25px;}
		.invalid-feedback {margin-bottom: 0px; position: absolute; margin-top: 0px; color: $white;;
			a {color: $white;}
		}
	}
	.navbar-menu {list-style: none; margin-bottom: 0px; padding: 0px;
		.nav-link {color: $white; font-size: 14px; padding: 0.5rem 0.4rem; position: relative;
			.fas {font-size: 30px;}
			&:focus {outline: none;}
			.badge {position: absolute; right: 12px; top: 0px;}
		}
		li.profile {padding-left: 0px;
			.nav-link > img {margin-right: 8px;}
			.dropdown-menu {left: auto !important; right: 0px;}
		}
	}
	.search {max-width: 425px;
		.input-group {
			.input-group-append {position: absolute; right: 0px; top: 0px; z-index: 9;}
			.input-group-text {background-color: transparent; border: none; color: $white;}
			.form-control {background-color: transparent; border-radius: 0px; padding-left: 10px; font-size: 14px; height: calc(1.5em + 0.75rem + 6px); border-color: rgba($white, 0.4); color: $white;
				&::placeholder {color: $white;}
			}
			.btn {font-size: 18px; font-weight: bold; padding: 5px 10px; color: $white;}
		}
	}
	&.fixed {position: fixed; width: 100%; left: 0px; top: 0px; z-index: 99;}
	@media(max-width: 991px) {
		form {
			.form-group {width: 200px;}
		}
	}
	@media(max-width: 767px) {
		.logo {text-align: center;
			img {width: 100px;}
		}

	}
	@media(max-width: 574px) {
		form {
			.form-group {width: 100%; margin-bottom: 5px !important;
				&.login-button {margin-top: 22px !important;}
			}
			.invalid-feedback {position: static;}
			.form-group.w-auto label {display: none;}
		}
		.navbar-menu .nav-link .badge {right: 0px;}
	}
} // Landing Header css end followers  following

.loggedIn {width: 100%; float: left;
	.mobile-search {display: none;}
	@media screen and (max-width: 991px) and (min-width: 768px) {
		header#landing-header .navbar-menu li.profile {padding-left: 0px;}
		header#landing-header .navbar-menu .nav-link {padding: 0.5rem 0.2rem;}
		header#landing-header .search {padding: 0px;}
	}
	@media(max-width: 1199px) {
		.container > .row {
			.col-lg-2.col-md-3.col-12 {flex: 0 0 130px; max-width: 130px;}
			.col-lg-10.col-md-9.col-12 {flex: 0 0 calc(100% - 130px); max-width: calc(100% - 130px);}
		}
	}
	@media(max-width: 767px) {
		.mobile-search {display: block;}
		header#landing-header .navbar-menu li.profile {padding-left: 0px;
			span {display: none;}
			.dropdown-toggle:after {display: none;}
		}
		header#landing-header .navbar-menu .nav-link {padding: 0.5rem 0.2rem;}
		header#landing-header .search {position: fixed; width: 130%; left: 0px; top: 0px; padding: 14px 15px; z-index: 9999; max-width: 100%; background-color: $primary; background-image: linear-gradient(lighten($primary, 10%), $primary); box-shadow: 0px 0px 8px rgba($black, 0.35);
			&.notshow {display: none;}
			&.show {display: block;}
		}
	}
	@media(max-width: 410px) {
		.container > .row {margin-left: -5px; margin-right: -5px;
			[class*="col-"] {padding-left: 5px; padding-right: 5px;}
		}
		header#landing-header .navbar-menu .nav-link .fas {font-size: 18px;}
	}
} // Header responsive



nav.landing-navbar {@include full-width(); background-color: $gray-300; padding: 0px 15px;
	.nav-link {border-radius: 0px; text-transform: uppercase; font-size: 13px; font-weight: bold; padding: 0.5rem 1.3rem; color: $gray-400; transition: all 0.3s ease-in-out;
		&:hover, &.active {background-color: lighten($primary, 72%); color: $primary;}
	}
	@media(max-width: 540px) {
		.nav-link {font-size: 12px; padding: 0.5rem .45rem;}
	}
} // Landing navbar css end

.landing-content {@include full-width(); padding-top: 40px; background-color: $white; 
	h2 {font-size: 30px; font-weight: bold; color: $gray-400; padding-bottom: 25px;}
	form {
		.form-group {position: relative;  margin-bottom: 33px;
			> label {position: absolute; margin: 0px; color: $gray-400; top: -13px; left: 15px; background-color: $white; border-radius: 6px; border-color: $gray-300; padding: 0px 4px;}
			.form-control {background-color: transparent; height: 55px; color: $gray-400; border-color: darken($gray-300, 7%);}
			textarea.form-control {height: 90px;}
		}
		.btn {text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 12px 30px;}
	}
	.text {font-size: 13px; font-weight: 600; color: #5e5e5d; margin-bottom: 50px; margin-top: 30px;}
	@media(max-width: 574px) {padding-top: 20px;
		h2 {font-size: 24px;}
		.text {margin-bottom: 25px;}
	}
} // Landing contant page css end

footer#footer {@include full-width(); background-color: $gray-300; border-top: 1px solid darken($gray-300, 7%); padding: 35px 0px;
	.footer-menu {list-style: none; padding: 0px; margin: 0px 40px 0px; font-size: 14px; font-weight: 600;
		li {color: $gray-600;
			+ li:before {content: " |"; padding: 0px 10px;}
			a {color: inherit; transition: all 0.3s ease-in-out;
				&:hover {color: $primary; text-decoration: none;}
			}
		}
	}
	.copyright {font-size: 14px; text-align: right; color: $gray-600;
		a {color: inherit;}
	}
	@media(max-width: 991px) {padding: 15px 0px;
		.footer-menu {margin: 0px; justify-content: center; width: 100%;}
		.app {text-align: center; margin-top: 15px; padding: 0px;}
		.copyright {text-align: center; width: 100%; margin-top: 15px;}
	}
} // Footer css end

.update-profile {margin: 30px 0px;
	@media(max-width: 574px) {margin: 15px 0px;}
} // Update profile page css end

.user-setting {margin: 30px 0px;
	@media(max-width: 574px) {margin: 15px 0px;}
} // User Setting page css end

.user-about {margin: 30px 0px; font-size: 14px; font-weight: 600; color: #3f3f40;
	h2 {font-size: 20px; font-weight: bold; text-align: center; margin-bottom: 1.5rem;}
	@media(max-width: 574px) {margin: 15px 0px;}
} // User About css end

.group-page {background-color: #e9ebee; padding: 20px 0px;
	.content {position: relative;}
	.mat-tab-group {border: 1px solid $gray-300; background-color: $white;}
	.mat-tab-body-wrapper {padding: 30px 10px;}
	.mat-tab-body-content {padding: 0px 15px;}
	.create .mat-flat-button {position: absolute; right: 30px; top: 7px; z-index: 4;
		&:hover, &:focus {text-decoration: none;}
	}
	.group-link-page {border: 1px solid $gray-300; background-color: $white; margin-bottom: 20px;
		.mat-tab-body-wrapper {padding: 0px !important;}
		.mat-tab-group {border: none !important;
			a {color: rgba($black,.87);}
		}
	}
	.allgrpu-load {margin-bottom: 15px;}
	@media(max-width: 991px) {
		.group-link-page {
			.mat-tab-group {padding-right: 120px;}
		}
		.create .mat-flat-button {right: 18px; top: 10px; min-width: 30px; padding: 0px 10px; line-height: 32px;}
	}
	@media(max-width: 767px) {
		.group-link-page {margin-top: 20px;
			.mat-tab-group {padding-right: 0px;}
		}
		.create .mat-flat-button {right: 18px; top: -16px; min-width: 30px; padding: 0px 10px; line-height: 32px;}
	}
} // Group page css end
 
.group-view {
	.info {
		h5 {font-size: 16px; margin-bottom: 3px; font-weight: bold;}
		p {font-size: 12px; font-weight: 500; margin-bottom: 4px;}
	}
} // Group View layout page css end

.event-about {
	.details {font-size: 13px; font-weight: 600;
		.far, .fas {width: 18px; transform: scale(1.2); color: $primary;}
	}
	.text {font-size: 14px; font-weight: 600;
		h6 {margin-bottom: 4px; font-weight: bold;}
	}
} // Event about css end

.user-setting {
	.block-user {list-style: none; padding: 0px; font-size: 14px; font-weight: 600; line-height: 40px;
		.mat-stroked-button {border: none; color: $primary;
			&:focus {outline: none}
		}
		li {width: 50%; float: left; display: flex; align-items: center;
			span {width: 120px;}
		}
	}
} // User Setting pade css end

.news-page {padding: 15px 0 0 0;
	.livetxt {font-size: 12px; font-weight: 700; color: #e32020; padding: 5px 0;}
	.card-one {padding: 0 30px 0 0;
		.content {
			time {color: #a19d9d !important; margin: 0 0 10px 0; display: block;}
		}
		.chatbox {border: 1px solid #cccccc; margin: 15px 0 15px 0; min-height: 600px;
			.chabox-top {
				ul {padding: 0 0 0 0; margin: 0 0 0 0; list-style: none;
					li {border-bottom: 1px solid #cccccc; padding: 12px;
						.image {text-align: center; width: 40px; margin: 0 22px 0 0; float: left;
							img {width: 40px; height: 40px; border-radius: 100%; object-fit: cover; object-position: center;}
							.name {font-size: 14px; color: #494a4b; text-align: center;}
						}
						.comment {font-size: 14px; color: #494a4b; text-align: left;}
					}
				}
			}
			.chatbox-bottom {padding: 18px;
				.form-control {font-size: 14px;border: 1px solid #cccccc;height: 85px;border-radius: 0px;box-shadow: none;color: #494a4b;}
				.smileicon {float: left; margin: 7px 0 0 0;
					a {margin: 0 7px 0 0;}
				}
				.btndiv {margin: 28px 0 0px 0; text-align: right;
					.btn-primary {color: #ffffff;line-height: 35px;font-size: 13px;text-transform: uppercase;padding: 0 17px;box-shadow: none;}
				}
				
			}
		}
	}

	.card-two {
		ul.item li a {font-size: 14px !important; font-weight: 700 !important;}
		ul.item.word-news li:first-child {
			a {font-size: 20px !important; font-weight: 700 !important; text-decoration: none !important;
				span {text-decoration: underline;}
			}
			a.sports_readmore {font-size: 14px !important; font-weight: 700; font-style: italic;}
			img {height: 195px; object-fit: cover; object-position: center;}
		}
		ul.item.word-news li {min-height: 55px;
			img {height: 55px; object-fit: cover; object-position: center;}
		}
		.ctn-new-list p {font-size: 14px; margin: 5px 0 0 0; color: #5e5e5d !important; text-decoration: none !important;}
	}

	.politics { padding-top: 10px; padding-bottom: 10px;
		ul.item li a {font-size: 14px !important; font-weight: 700 !important; text-decoration: none !important;}
		ul.item.politics li:first-child {
			a {font-size: 20px !important; font-weight: 700 !important;
				span {text-decoration: underline;}
			}
			a.sports_readmore {font-size: 14px !important; font-weight: 700; font-style: italic;}
			img {height: 195px; object-fit: cover; object-position: center;}
		}
		ul.item.politics li {min-height: 55px;
			img {height: 55px; object-fit: cover; object-position: center;}
		}
		.ctn-new-list p {font-size: 14px; margin: 5px 0 0 0; color: #5e5e5d !important;}
	}

	.entertainmentbox { padding-top: 10px; padding-bottom: 10px;
		ul.item li a {font-size: 14px !important; font-weight: 700 !important;}
		ul.item.entertainment li:first-child {
			a {font-size: 20px !important; font-weight: 700 !important; text-decoration: none !important;
				span {text-decoration: underline;}
			}
			a.sports_readmore {font-size: 14px !important; font-weight: 700; font-style: italic;}
			img {height: 195px; object-fit: cover; object-position: center;}
		}
		ul.item.entertainment li {min-height: 55px;
			img {height: 55px; object-fit: cover; object-position: center;}
		}
		.ctn-new-list p {font-size: 14px; margin: 5px 0 0 0; color: #5e5e5d !important;}
	}

	.travelinfluencer { padding-top: 10px; padding-bottom: 10px;
		ul.item li p {display: block !important;}
		ul.item li .sports_readmore {display: block !important;}
		ul.item li {width: 33.3333%; display: inline-block !important; vertical-align: top;
			span {font-size: 14px; font-weight: 700;}
			p {display: none !important;}
			.sports_readmore {display: none !important;}
			img {height: 55px; object-fit: cover; object-position: center;}
		}
		ul.item.lifestyle li:nth-child(1),  ul.item.lifestyle li:nth-child(2) {width: 50%; float: left;
			img {width: 100%; max-width: 100%  !important; height: 280px; object-position: center; object-fit: cover;}
			.ctn-news-img {float: none !important; width: 100%; max-width: 100% !important;
				img {max-width: 100% !important; height: 280px;}
			}
			.detail {background: $white; box-shadow: 0 0 5px #cccccc; max-width: 390px; height: 198px; margin: -115px auto 35px auto; padding: 18px 18px; position: relative;
				span {display: block; font-size: 16px; font-weight: 900; color: $black; margin: 0 0 5px 0;}
				p {font-size: 14px; color: #5e5e5d !important; margin: 0 0 5px 0; display: inline-block !important;}
				.sports_readmore {font-size: 14px; color: 2c2c2b; display: inline-block !important;}
			}
		}
		
	}

	.sportsbox {padding-top: 10px; padding-bottom: 10px;}

	.subscribe {
		.content {font-size: 14px; color: #5e5e5d; margin: -15px 0 15px 0;}
		.form-control {font-size: 15px;border: 1px solid #cccccc;height: 55px;border-radius: 4px;box-shadow: none;color: #575656;}
		label {font-size: 14px; font-weight: 400; padding: 0 10px; margin: 0 0 -14px 5px; color: #2c2c2b; background: $white; float: left;}
		.form-group {margin-bottom: 25px;}
		.form-group-1 {margin-bottom: 15px;}
		.btndiv {
			.btn-primary {color: #ffffff;line-height: 55px;font-size: 14px; font-weight: 700; text-transform: uppercase;padding: 0 35px;box-shadow: none;}
		}
	}

    .mat-tab-label {padding: 0 6px; min-width: 100px;}
	.mat-tab-body-content {padding: 15px 0px;}
	.page-card {margin-bottom: 30px; position: relative;
		.card-header {margin-bottom: 15px; padding: 0px;
			h3 span {padding: 10px 18px; float: left; font-size: 16px;}
		}
		&.card-black .card-header {border-bottom-color: $black;}
		&.card-danger .card-header {border-bottom-color: $danger;}
		&.card-success .card-header {border-bottom-color: $success;}
		&.card-info .card-header {border-bottom-color: $info;}
		&.card-story {
			.card-header {margin-bottom: 0px; padding: 0.65rem 15px; margin-top: 10px;
				h3 {font-size: 14px; font-weight: bold;}
			}
			.card-body {padding: 1rem 12px;}
			.owl-theme .owl-nav {top: -50px; right: 0px;}
		}
	}
	.owl-theme .owl-nav {margin: 0px; position: absolute;
		[class*='owl-'] {background-color: transparent; color: $gray-600; margin: 0px; font-size: 18px; padding: 4px 4px;
			&:hover {color: $primary;}
		}
	}
	.card.feature {
		.owl-carousel {padding-bottom: 20px;}
		.owl-theme .owl-nav {bottom: -15px; right: 0px;}
	}
	.top-arrow {
		.owl-theme .owl-nav {top: -50px; right: 0px;}
	}

} // News page css end

.videos-page {
	.view-box .caption .count-group {padding: 10px 0px; font-size: 13px;}
	.view-box .caption .content {padding: 15px 0px 15px;}
	.view-box .caption .content .text {display: none;}
	.view-box .caption .content time {font-size: 13px; color: #a19d9d;}
}


.chat-user-list {padding: 0px 0px 0px 0px; margin: 0px; list-style: none; //max-height: 480px; overflow-x: hidden; overflow-y: scroll;
	li {border-bottom: 1px solid $gray-300; position: relative;
		//&:last-child {border-bottom: none;}
		&:hover .action a {opacity: 1;}
		.action {position: absolute; right: 2px; z-index: 4; top: -4px;
			a { opacity: 0; cursor: pointer;}
			a[aria-expanded="true"] { opacity: 1;}
		}
		.count {color: $white; background-color: $primary; width: 30px; height: 30px; font-size: 13px; font-weight: bold; border-radius: 50%; position: absolute; top: 30px; right: 6px; z-index: 6;}
		.img {width: 50px; margin-right: 10px;}
		.caption {width: calc(100% - 60px); }
		.text {font-size: 14px; color: $gray-400;}
		a {width: 100%; padding: 15px 10px; border-left: 3px solid transparent; color: $dark;
			&:hover, &.active {background-color: lighten($primary, 72%); border-left-color: $primary;}
			h4 {font-size: 16px; font-weight: 700; 
				time {font-size: 11px; color: $gray-400;}
			}
		}
	}
}

.message-page {width: 100%; float: left; padding: 30px 0px;
	.slimscroll-grid {z-index: 5 !important;}
	.slimscroll-bar {z-index: 6 !important;}
	.msg-friend-list {position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: $white; z-index: 7;}
	.user-box {background-color: $white; border: 1px solid $gray-300; overflow: hidden; position: relative; min-height: 585px;}
	.msg-list-box {background-color: $white; border: 1px solid $gray-300;}
	.search-list-group {border-bottom: 1px solid $gray-300; background-color: lighten($primary, 72%);
		.crate-group {
			.mat-stroked-button {padding: 0px 5px; min-width: 35px;}
		}
	}
	.user-list {padding: 0px 0px 0px 0px; margin: 0px; list-style: none; //max-height: 480px; overflow-x: hidden; overflow-y: scroll;
		li {border-bottom: 1px solid $gray-300; position: relative;
			//&:last-child {border-bottom: none;}
			&:hover .action a {opacity: 1;}
			.action {position: absolute; right: 2px; z-index: 4; top: -4px;
				a { opacity: 0; cursor: pointer;}
				a[aria-expanded="true"] { opacity: 1;}
			}
			.count {color: $white; background-color: $primary; width: 30px; height: 30px; font-size: 13px; font-weight: bold; border-radius: 50%; position: absolute; top: 30px; right: 6px; z-index: 6;}
			.img {width: 50px; margin-right: 10px;}
			.caption {width: calc(100% - 60px); }
			.text {font-size: 14px; color: $gray-400;}
			a {width: 100%; padding: 15px 10px; border-left: 3px solid transparent; color: $dark;
				&:hover, &.active {background-color: lighten($primary, 72%); border-left-color: $primary;}
				h4 {font-size: 16px; font-weight: 700; 
					time {font-size: 11px; color: $gray-400;}
				}
			}
		}
	}
	.left-scroll {height: 515px; overflow-y: auto;}
	.right-scroll {height: 412px; overflow: auto;
		ng-scrollbar-y {display: block !important;}
	}
	.user-info {padding: 12px 0px;
		.caption {
			h4 {font-size: 20px; font-weight: bold; margin-bottom: 0px; }
			time {font-size: 12px; color: darken($white, 20%); }
			.right {
				.mat-button {padding: 0px 10px; min-width: 10px; 
					&:hover, &:focus {color: $white;}
				}
			}
		}
	}
	.msg-list {padding: 10px 12px 10px 20px; margin: 0px; list-style: none; float: left; width: 100%;
		li {width: 100%; margin-bottom: 12px; display: flex; align-items: flex-start;
			app-message-item {width: 100%; margin-bottom: 12px; display: flex; align-items: flex-start;}
			&.mymsg {justify-content: flex-end; float: right;
				app-message-item {justify-content: flex-end; float: right;}
				.msg-text {background-color: lighten($primary, 73%);}
				.pic {order: 1 !important; padding-left: 6px;}
			}
			&.youmsg {float: left;
				app-message-item {float: left;}
			}
			.pic {max-width: 100px; line-height: 18px; text-align: center;
				img {margin-bottom: 2px;}
				small {font-size: 10px; width: 100%; float: left;}
			}
			.msg-text {font-size: 12px; font-weight: 600; padding: 10px; color: $gray-400; max-width: calc(100% - 100px);
				time {font-size: 11px; padding-left: 8px;}
			}
		}
	}
	.comment-form {position: relative;
		.uploads {width: 100%; left: 0px; bottom: calc(100% + 1px); position: absolute; background-color: $white; padding: 5px 10px; border-top: 1px solid $gray-300; z-index: 1;
			.images {width: 60px; margin-bottom: 6px; padding-left: 4px; padding-right: 4px; position: relative;
				img {width: 100%; height: 50px; object-fit: cover; object-position: center;}
				.btn {position: absolute; right: 4px; font-size: 12px; padding: 3px 6px;}
			}
		}
		.attachment {position: relative; padding: 5px;
			.file {position: relative; color: $white; background-color: $primary;
				input {position: absolute; width: 64px; left: 0px; top: 0px; height: 34px; opacity: 0; cursor: pointer;}
			}
		}
		.text {
			.mat-form-field-appearance-legacy .mat-form-field-wrapper {margin-bottom: 0px; padding-bottom: 0px;}
			.mat-form-field-appearance-legacy .mat-form-field-underline {display: none;}
			.mat-form-field-appearance-legacy .mat-form-field-infix {padding-top: 0px; margin-top: 0px;}
			textarea.mat-input-element {min-height: 62px;}
		}
		.botton {padding: 5px; position: relative;

		}
	}
	.blank-msg {height: 582px;}
	@media(max-width: 767px) {
		.user-box {min-height: 400px; margin-bottom: 15px;}
		.left-scroll {height: 330px;}
	}
} // Message page css end

.user-setting {
	.mat-form-field-appearance-outline .mat-form-field-wrapper {padding-bottom: 0px; margin: 0px;}
	.mat-form-field-label-wrapper {padding-top: 0px;}
	.mat-form-field-infix {padding: 15px 0px 7px 0px; margin: 0px; border-top: none;}
	.mat-form-field-appearance-outline .mat-select-arrow-wrapper {transform: translateY(-05%);}
}



/// ===========  Theme Customize =====

.theme-blue {
	a, a:hover {color: $blue;}
	.btn-link {color: rgba($blue, 0.5);}
	.text-primary {color: $blue !important;}
	.bg-primary {background-color: $blue !important;}
	.spinner-size.mat-primary circle {stroke: $blue;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $blue;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $blue;}

	.btn-primary {border-color: $blue; background-color: $blue; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($blue, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($blue, 0.25);}
	}
	header#landing-header {background-color: $blue; background-image: linear-gradient(lighten($blue, 10%), $blue); box-shadow: 0px 0px 8px rgba($black, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $blue;}
		&.active {background-color: rgba($blue, 0.1); color: $blue;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($blue, 72%); color: $blue;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $blue;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $blue;}
} 


.theme-purple {
	a, a:hover {color: $purple;}
	.btn-link {color: rgba($purple, 0.5);}
	.text-primary {color: $purple !important;}
	.bg-primary {background-color: $purple !important;}
	.spinner-size.mat-primary circle {stroke: $purple;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $purple;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $purple;}

	.btn-primary {border-color: $purple; background-color: $purple; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($purple, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($purple, 0.25);}
	}
	header#landing-header {background-color: $purple; background-image: linear-gradient(lighten($purple, 10%), $purple); box-shadow: 0px 0px 8px rgba($black, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $purple;}
		&.active {background-color: rgba($purple, 0.1); color: $purple;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($purple, 72%); color: $purple;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $purple;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $purple;}
} 

.theme-green {
	a, a:hover {color: $green;}
	.btn-link {color: rgba($green, 0.5);}
	.text-primary {color: $green !important;}
	.bg-primary {background-color: $green !important;}
	.spinner-size.mat-primary circle {stroke: $green;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $green;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $green;}

	.btn-primary {border-color: $green; background-color: $green; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($green, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($green, 0.25);}
	}
	header#landing-header {background-color: $green; background-image: linear-gradient(lighten($green, 10%), $green); box-shadow: 0px 0px 8px rgba($black, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $green;}
		&.active {background-color: rgba($green, 0.1); color: $green;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($green, 72%); color: $green;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $green;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $green;}
} 

.theme-black {
	a, a:hover {color: $black;}
	.btn-link {color: rgba($black, 0.5);}
	.text-primary {color: $black !important;}
	.bg-primary {background-color: $black !important;}
	.spinner-size.mat-primary circle {stroke: $black;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $black;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $black;}

	.btn-primary {border-color: $black; background-color: $black; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($black, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($black, 0.25);}
	}
	header#landing-header {background-color: $black; background-image: linear-gradient(lighten($black, 10%), $black); box-shadow: 0px 0px 8px rgba($black, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $black;}
		&.active {background-color: rgba($black, 0.1); color: $black;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($black, 72%); color: $black;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $black;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $black;}
} 

.theme-orange {
	a, a:hover {color: $orange;}
	.btn-link {color: rgba($orange, 0.5);}
	.text-primary {color: $orange !important;}
	.bg-primary {background-color: $orange !important;}
	.spinner-size.mat-primary circle {stroke: $orange;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $orange;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $orange;}

	.btn-primary {border-color: $orange; background-color: $orange; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($orange, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($orange, 0.25);}
	}
	header#landing-header {background-color: $orange; background-image: linear-gradient(lighten($orange, 10%), $orange); box-shadow: 0px 0px 8px rgba($orange, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $orange;}
		&.active {background-color: rgba($orange, 0.1); color: $orange;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($orange, 72%); color: $orange;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $orange;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $orange;}
} 

.theme-teal {
	a, a:hover {color: $teal;}
	.btn-link {color: rgba($teal, 0.5);}
	.text-primary {color: $teal !important;}
	.bg-primary {background-color: $teal !important;}
	.spinner-size.mat-primary circle {stroke: $teal;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $teal;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $teal;}

	.btn-primary {border-color: $teal; background-color: $teal; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($teal, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($teal, 0.25);}
	}
	header#landing-header {background-color: $teal; background-image: linear-gradient(lighten($teal, 10%), $teal); box-shadow: 0px 0px 8px rgba($teal, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $teal;}
		&.active {background-color: rgba($teal, 0.1); color: $teal;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($teal, 72%); color: $teal;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $teal;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $teal;}
} 

.theme-cyan {
	a, a:hover {color: $cyan;}
	.btn-link {color: rgba($cyan, 0.5);}
	.text-primary {color: $cyan !important;}
	.bg-primary {background-color: $cyan !important;}
	.spinner-size.mat-primary circle {stroke: $cyan;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $cyan;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $cyan;}

	.btn-primary {border-color: $cyan; background-color: $cyan; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($cyan, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($cyan, 0.25);}
	}
	header#landing-header {background-color: $cyan; background-image: linear-gradient(lighten($cyan, 10%), $cyan); box-shadow: 0px 0px 8px rgba($cyan, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $cyan;}
		&.active {background-color: rgba($cyan, 0.1); color: $cyan;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($cyan, 72%); color: $cyan;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $cyan;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $cyan;}
} 

.theme-gray {
	a, a:hover {color: $gray3;}
	.btn-link {color: rgba($gray3, 0.5);}
	.text-primary {color: $gray3 !important;}
	.bg-primary {background-color: $gray3 !important;}
	.spinner-size.mat-primary circle {stroke: $gray3;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $gray3;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $gray3;}

	.btn-primary {border-color: $gray3; background-color: $gray3; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($gray3, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($gray3, 0.25);}
	}
	header#landing-header {background-color: $gray3; background-image: linear-gradient(lighten($gray3, 10%), $gray3); box-shadow: 0px 0px 8px rgba($gray3, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $gray3;}
		&.active {background-color: rgba($gray3, 0.1); color: $gray3;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($gray3, 72%); color: $gray3;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $gray3;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $gray3;}
} 

.theme-red {
	a, a:hover {color: $red;}
	.btn-link {color: rgba($red, 0.5);}
	.text-primary {color: $red !important;}
	.bg-primary {background-color: $red !important;}
	.spinner-size.mat-primary circle {stroke: $red;}
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label {color: $red;}
	.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary, .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $red;}

	.btn-primary {border-color: $red; background-color: $red; color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($red, 0.25);}
		&:hover {color: $white;}
	}
	a.mat-primary:hover, button.mat-primary:hover {color: $white;}
	.form-control {border-color: $white;
		&:focus {box-shadow: 0 0 0 0.2rem rgba($red, 0.25);}
	}
	header#landing-header {background-color: $red; background-image: linear-gradient(lighten($red, 10%), $red); box-shadow: 0px 0px 8px rgba($red, 0.35);}
	.timeline-menu .nav-link {
		&:hover {color: $red;}
		&.active {background-color: rgba($red, 0.1); color: $red;}
	}
	nav.landing-navbar .nav-link {
		&:hover, &.active {background-color: lighten($red, 72%); color: $red;}
	}
	.profile-card .card-header, .blog-popular ul li .image .count {background-color: $red;}
	.profile-card ul li a, footer#footer .footer-menu li a:hover, .blog-popular ul li .caption h4 a:hover, .new-post .button ul li button, .new-post .button ul li label, .timeline-post .comment-list .likes .btn b, .timeline-post .comment-share .btn:hover {color: $red;}
} 

// balloons starts

/* Sass Arrays and Mixins */
$colors: $primary, $info, $success, $danger, $dark, $black, $pink, $purple, $blue;

@mixin browsers($info) {
  animation: $info;
  -webkit-animation: $info;
  -moz-animation: $info;
  -ms-animation: $info;
  -o-animation: $info;
}


.balloons {
  height: 100%; width: 100%; position: fixed; left: 0px; bottom: 0px; z-index: 99; pointer-events: none;
}

.balloon {
	height: 130px; width: 104px; border-radius: 80% 80% 80% 80%; background-color: red; position: relative; margin: 0 5px;
	&:before {
		content: ""; color: rgba(182, 15, 97, 0.9); position: absolute; bottom: -9px; left: 50%; transform: translateX(-50%); border-left: 5px solid transparent; border-right: 5px solid transparent; border-bottom: 10px solid red;
	}

	.balloon-ref {
		position: absolute; left: 0px; top: 0; width: 100%; height: 80%; border-radius: 90%; background: rgb(255,255,255); background: radial-gradient(circle, rgba(255,255,255,0.41015625) 0%, rgba(148,214,233,0) 100%);
   }
   span.top-c {
		position: absolute; top: 0px; left: 20px; width: 15px; height: 40px; border-radius: 50%; background-color: rgba(255,255,255,0.6); transform: rotate(48deg);
   }
   span.left-c {
	  position: absolute; top: 36px; left: 6px; width: 7px; height: 7px; border-radius: 50%; background-color: rgba(255,255,255,0.6); transform: rotate(48deg);
   }

}

@for $i from 0 through 40 {
  $the-color: nth($colors, random(length($colors)));
  .balloon#{$i} {
    $speed: random(50) + 7 + s;
    $delay: - (random(70) + 50 + s);
    background-color: $the-color;
    left: random(70) + 0%;
    @include browsers(flyingBalloon $speed $delay linear infinite);
    &:before {
      border-bottom: 10px solid $the-color;
    }
  }
}

@keyframes flyingBalloon {
  from {
    bottom: -20%;
  }
  to {
    bottom: 110%;
  }
}
// balloons ends

.albums-list-view .timeline-post .comment-list .likes .btn-share {display: none;}


.privacy-scroll.going-user {
	.mat-checkbox-inner-container {display: none;}
}


@media(max-width: 991px) {
	.user-banner {min-height: 10px !important;
		.image {height: auto;}
	}
}
@media(max-width: 767px) {
	.row {margin-left: -5px; margin-right: -5px;}
	[class*="col"] {padding-left: 5px; padding-right: 5px;}
	.container {padding-left: 5px; padding-right: 5px;}
	app-about {
		.user-about {margin: 15px 0px;}
	} // About Information responsive css end
	.page-card {margin-bottom: 15px !important;
		.card-header {padding: 0.55rem 10px;
			.btn-link span {display: none;}
			.input-group {margin-right: 0px !important; width: 100%; max-width: 100%; margin: 6px 0px;}
		}
		.card-body {padding: 1rem 10px;
			> .py-3 {padding: 0px !important;}
		}
	} // All card css end
	.custom-dialog {
		.header {padding: 6px 10px;
			h3 {font-size: 16px;}
		}
	}
	.mat-dialog-content {padding: 10px !important; max-height: calc(100vh - 115px) !important; min-height: calc(100vh - 115px) !important;}
	.mat-dialog-actions {padding: 8px 10px !important;}
	.cdk-overlay-pane {max-width: 100vw !important; width: 100% !important; }

	.inner-page {padding: 15px 0px;}
	.custom-card.setting-menu {margin-top: 15px; margin-bottom: 0px;}
	.group-banner {
		.actions {padding-bottom: 8px;
			button, a {margin-bottom: 6px;}
			.col {flex-basis: inherit; flex-grow: inherit; max-width: none; width: auto;}
		}
	}
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    user-select: none;
}
.support-status .mat-form-field-wrapper {padding-bottom: 2px;}


@media(max-width: 574px) {
	app-dashboard-layout .dashboard-page {
		app-user-profile, app-explore-menu, app-trends-for-you {display: none;}
	}
	.mat-select-panel {min-width: calc(100% - 15px) !important; margin-left: 48px;}
}
//app-announcement-video .iframe-video {}

.example-tooltip-red {background-color: #fff; margin-top: 0px !important; color: #333 !important; font-weight: bold !important;}

webcam {
	video {width: 100%;}
}

app-user-timeline {
	app-advertisement {margin-top: 40px; float: left; width: 100%;}
}

.people-you-know {
	.list-group-item {margin-bottom: 10px; padding: 0px; border: none;
		.caption {padding: 10px; border: 1px solid #ddd; border-top: none;
			h6 a {font-size: 18px; color: inherit; line-height: 30px;
				&:hover {color: $primary;}
			}
		}
	}
	.owl-theme .owl-nav {
		.owl-prev {position: absolute; left: 0px; top: 50%; margin-top: -20px;}
		.owl-next {position: absolute; right: 0px; top: 50%; margin-top: -20px;}
	}
}

.view-box {margin-bottom: 30px;
	.caption {
		.content {padding: 10px 15px 15px;
			time {color: $gray-300;}
			.text {font-size: 14px;}
		}
		.count-group {border-top: 1px solid $gray-300; border-bottom: 1px solid $gray-300; padding: 10px 15px; font-size: 14px;
			b, i {color: $primary;}
		}
	}
}